<template>
    <div class="position-relative" :class="elClass">
        <div v-if="loading" class="init-box ratio2_1 bg-light">
            <div class="box-content d-flex justify-content-center align-items-center">
                <div v-if="passwdResource">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">
                                {{ passwdResource.message }}
                            </h4>
                            <form
                                @submit.prevent="authPassword(passwdResource)"
                            >
                                <div class="form-group">
                                    <input
                                        type="password"
                                        class="form-control"
                                        placeholder="请输入密码"
                                        v-model="password"
                                        required
                                    />
                                </div>
                                <div class="text-center">
                                    <button
                                        type="reset"
                                        class="btn btn-sm btn-round btn-default"
                                    >
                                        重置
                                    </button>
                                    <button
                                        type="submit"
                                        class="btn btn-sm btn-round btn-info"
                                    >
                                        提交
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div v-if="bookResource">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">
                                {{ bookResource.message }}
                            </h4>
                            <form @submit.prevent="bookInfo(bookResource)">
                                <div class="form-group">
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="请输入姓名"
                                        v-model="book.realname"
                                        required
                                    />
                                </div>
                                <div class="form-group">
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="请输入工作单位"
                                        v-model="book.workplace"
                                        required
                                    />
                                </div>
                                <div class="text-center">
                                    <button
                                        type="reset"
                                        v-on:click="reset()"
                                        class="btn btn-sm btn-round btn-default"
                                    >
                                        重置
                                    </button>
                                    <button
                                        type="submit"
                                        class="btn btn-sm btn-round btn-info"
                                    >
                                        提交
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <h3 v-else-if="authorize" class="title">
                    {{ authorize.message }}
                </h3>
                <h3 v-else-if="ending" class="py-5">{{ endtext }}</h3>
                <h1 v-else-if="countDown.cd" class="title">
                    {{ formatedCountDown }}
                </h1>
                <svg
                    v-else
                    class="spinner"
                    width="65px"
                    height="65px"
                    viewBox="0 0 66 66"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle
                        class="path"
                        fill="none"
                        stroke-width="6"
                        stroke-linecap="round"
                        cx="33"
                        cy="33"
                        r="30"
                    />
                </svg>
            </div>
        </div>
        <!-- <v-player v-else-if="isStream" ref="tcPlayer" :options="dpOpts" @load="onLoad" @play="videoIsPlaying" @pause="videoIsPaused" @ended="videoIsEnded"></v-player> -->
        <d-player
            v-else
            ref="player"
            :options="dpOpts"
            @play="videoIsPlaying"
            @pause="videoIsPaused"
            @ended="videoIsEnded"
        ></d-player>
        <div
            v-if="paymentOverlay"
            class="card-img-overlay video-overlay d-flex justify-content-center align-items-center"
        >
            <payment-code
                el-class
                :call-from="dpOpts.video.orderApi"
                @closed="whenPaymentClosed"
            ></payment-code>
        </div>
    </div>
</template>

<script>
import { LectureService } from "../../services/LectureService";
import { toast, formatToHHMMSS, refreshCsrfToken } from "../../../helpers/utils";
import VueDPlayer from "vue-dplayer";
// import { VTcPlayer } from "v-tcplayer";
import PaymentCode from "../order/PaymentCode.vue";
import flvjs from "flv.js";
import Hls from "hls.js";

export default {
    name: "LecturePlayer",

    props: {
        elClass: {
            type: [String, Object]
        },

        callFrom: {
            type: String
        },

        isLiving: {
            type: Boolean,
            default: false
        },

        dPlayerAttributes: {
            type: Object
        },

        lectureId: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            playTimes: 0,
            lectureService: null,
            lecture: null,
            dpOptsDefaults: {
                live: false
            },
            player: null,
            api: this.callFrom,
            isStream: this.isLiving,
            loading: true,
            passwdResource: null,
            password: null,
            authorize: null,
            paymentOverlay: false,
            forceReload: false,
            countDown: {
                handler: null,
                cd: null
            },
            pos: 0,
            currentChatRoom: null,
            bookResource: null,
            book: {
                realname: null,
                workplace: null
            },
            showUserBtn: false,
            endtext: "直播已结束",
            ending: false
        };
    },

    mounted() {
        this.lectureService = new LectureService();

        // this.lectureService.lastVisit(this.lectureId);

        // 监听切换事件
        this.lectureService.hub().$on("switch-player", e => {
            this.switchPlayer(e);
        });

        // 监听用户登录事件
        this.lectureService.hub().$on("user-logged-in", e => {
            this.init();

            setTimeout(() => {
                $("#dlgSignin").length && $("#dlgSignin").modal("hide");
            }, 1500);
        });
        // let _this = this;
        // $("body").on('click','.dplayer-quality-item',function() {
        //     let index = $(this).data('index');
        //     this.pos = index;
        //     _this.loading = true;
        //     _this.init(this.pos);
        // })
    },

    methods: {
        init() {
            this.lectureService
                .player(this.api)
                .then(resp => {
                    if (this.isStream) {
                        this.loading = false;
                        if (this.forceReload) {
                            this.showUserBtn = resp.show_user_btn;
                            this.livePlayer(resp.data).createChat(resp.room);
                        } else {
                            return this.switchPlayer(
                                { api: resp.api, isStream: true },
                                true
                            );
                        }
                    } else {
                        this.vodPlayer(resp.data).destroyChat();
                    }

                    // this.getPlayer().on('canplay', function() {
                    //     console.log('player canplay');
                    // });
                    this.addOneTime();
                    this.loading = false;
                })
                .catch(err => {
                    if (err.status == 423) {
                        if (err.data.auth) {
                            this.passwdResource = err.data;
                        } else if (err.data.book) {
                            this.bookResource = err.data;
                        } else if (err.data.ending) {
                            this.endtext = err.data.message;
                            this.ending = err.data.ending;
                        }
                    } else if (err.status == 403) {
                        this.authorize = err.data;
                    } else if (err.data.cd) {
                        this.startCoundDown(err.data.cd);
                    } else {
                        toast(err.data.message, "snackbar-error");
                    }
                });
        },

        vodPlayer(options) {
            this.dpOptsDefaults.live = false;

            this.dpOptsDefaults.video = {
                url: options.playable_url,
                pic: options.cover,
                trial: options.is_trial,
                orderApi: options.order
            };

            return this;
        },

        livePlayer(data) {
            this.dpOptsDefaults.live = true;
            this.dpOptsDefaults.video = this.autoQuality(data);
            this.dpOptsDefaults.video.trial = false;
            // this.dpOptsDefaults.video = {
            //     trial: false,// 直播没有trial
            // };
            // this.addOneTime(); // 默认增加一次点击, 使自动播放
            this.playTimes = 2;
            return this;
        },

        switchPlayer(e, force) {
            this.api = e.api;
            this.isStream = e.isStream;
            this.loading = true;
            this.authorize = null;
            this.forceReload = force || false;
            this.clearPassword();
            this.clearBookResource().init();
        },

        destroyChat() {
            if (this.currentChatRoom) {
                this.lectureService
                    .hub()
                    .$emit("close-chat-room", { room: this.currentChatRoom });
                this.currentChatRoom = null;
            }

            return this;
        },

        createChat(room) {
            this.currentChatRoom = room;
            this.lectureService.hub().$emit("init-chat-room", {
                room: room,
                showUserBtn: this.showUserBtn
            });
            return this;
        },

        startCoundDown(cd) {
            this.countDown.cd = cd;

            this.countDown.handler = setInterval(() => {
                if (this.countDown.cd === 0) {
                    clearInterval(this.countDown.handler);

                    this.countDown.handler = null;
                    this.countDown.cd = null;

                    this.init();

                    return;
                }

                this.countDown.cd--;
            }, 1000);
        },

        videoIsPlaying() {
            this.paymentOverlay = false;
            if (this.dpOpts.video.trial) {
                this.getPlayer().notice(
                    "您正在试看本视频, 完整视频需要付费才能观看",
                    15000
                );
            }
            this.getPlayer().on("fullscreen_cancel", function() {
                console.log("player fullscreen_cancel");
            });
        },

        videoIsEnded() {
            if (this.dpOpts.video.trial) {
                this.paymentOverlay = true;
            }
        },

        videoIsPaused() {
            if (this.dpOpts.video.trial) {
                this.paymentOverlay = true;
            }
        },
        onLoad() {},

        getPlayer() {
            return this.$refs.player.dp;
        },

        authPassword(resource) {
            this.lectureService
                .auth(resource.auth, { password: this.password })
                .then(resp => {
                    this.clearPassword();
                    this.init();
                })
                .catch(err => {
                    toast(err.data.message, "snackbar-error");
                });
        },

        clearPassword() {
            this.passwdResource = null;
            this.password = null;

            return this;
        },
        bookInfo(resource) {
            this.lectureService
                .book(resource.book, this.book)
                .then(resp => {
                    refreshCsrfToken(resp.csrf_token);
                    this.lectureService.hub().$emit("update-user-menu");
                    this.clearBookResource().init();
                })
                .catch(err => {
                    toast(err.data.message, "snackbar-error");
                });
        },

        clearBookResource() {
            this.bookResource = null;
            this.book = {
                realname: null,
                workplace: null
            };

            return this;
        },

        reset() {
            this.book = {
                realname: null,
                workplace: null
            };
        },

        addOneTime() {
            this.playTimes += 1;

            return this;
        },

        /**
         * 动态获取视频质量
         *
         * @param {Object} data
         */
        autoQuality(data, pos) {
            //如果不能播放flv, 尝试用hls格式
            // if (flvjs.isSupported()) {
            //      return {
            //         url: data.flv.hd,
            //         type: 'httpFlv',
            //         customType: {
            //             httpFlv: (video, player) => {
            //                 const flvPlayer = flvjs.createPlayer({
            //                     type: "flv",
            //                     url: video.src
            //                 });

            //                 flvPlayer.attachMediaElement(video);
            //                 flvPlayer.load();
            //             }
            //         }
            //     };
            // } else {
            return {
                url: data.m3u8.hd,
                type: "cusHls",
                customType: {
                    cusHls: (video, player) => {
                        const hls = new Hls();
                        hls.loadSource(video.src);
                        hls.attachMedia(video);
                    }
                }
            };
            // }
            // return {
            //     quality: [{
            //         name: '标清',
            //         url: data.m3u8.sd,
            //         type: 'hls'
            //     },{
            //         name: '高清',
            //         url: data.m3u8.hd,
            //         type: 'hls'
            //     }],
            //     defaultQuality: pos,
            // }
        },

        whenPaymentClosed() {
            this.paymentOverlay = false;
            this.getPlayer().play();
        }
    },

    computed: {
        dpOpts() {
            let opts = {};

            Object.assign(
                opts,
                this.dpOptsDefaults,
                this.dPlayerAttributes || {}
            );

            opts.autoplay = this.playTimes > 1 ? true : false;

            return opts;
        },

        calcWrapHeight() {},

        formatedCountDown() {
            return formatToHHMMSS(this.countDown.cd);
        }
    },

    components: {
        "d-player": VueDPlayer,
        "payment-code": PaymentCode,
        // "v-player": VTcPlayer
    }
};
</script>

<style lang="scss">
.init-box {
    position: relative;
    width: 100%;

    &:before {
        content: "";
        display: block;
        padding-top: 100%; /* initial ratio of 1:1*/
    }

    .box-content {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
}

/* Other ratios */
.ratio2_1:before {
    padding-top: 50%;
}

.ratio1_2:before {
    padding-top: 200%;
}

.ratio4_3:before {
    padding-top: 75%;
}

.ratio16_9:before {
    padding-top: 56.25%;
}

.video-overlay {
    // background-color: rgba(0, 0, 0, 0.5);
    // bottom: 41px;
    background: url(../../../../img/pay/bg-pay.jpg) no-repeat;
    background-size: cover;
}
</style>
