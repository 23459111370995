<template>
  <span v-if="number">
      {{ number }} /
  </span>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      number: '',
      timer: null
    }
  },
  props: {
      duration: {
          type: Number,
          default: 60000
      }
  },
  computed: {
    ...mapState({
      currentRoom: state => state.group.currentRoom
    })
  },
  mounted() {
      this.query();
      this.startQuery();
  },
  methods: {
    query() {
        return axios.post(this.currentRoom.onlines_addr || `/rooms/${this.currentRoom.slug}/onlines`)
            .then(resp => this.number = resp.data.data);
    },

    startQuery() {
        this.timer = setInterval(() => {
            this.query();
        }, this.duration);
    }
  }
}
</script>
