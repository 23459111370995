import tim from '../../tim/tim'

const groupModules = {
  state: {
    groupList: [],
    currentMemberList: [],
    createGroupModelVisible: false,
    currentGroupNotification: undefined
  },
  getters: {
    hasGroupList: state => state.groupList.length > 0
  },
    mutations: {
        updateGroupNotification(state, notification) {
            state.currentGroupNotification = notification === 'close' ? undefined : notification;
        },
    updateGroupList(state, groupList) {
      state.groupList = groupList
    },
    updateCreateGroupModelVisible(state, visible) {
      state.createGroupModelVisible = visible
    },
    updateCurrentMemberList(state, memberList) {
      state.currentMemberList = [...state.currentMemberList, ...memberList]
    },
    deleteGroupMemeber(state, userID) {
      state.currentMemberList = state.currentMemberList.filter((member) => member.userID !== userID)
    },
    deleteGroupMemberList(state, userIDList) {
      state.currentMemberList = state.currentMemberList.filter((member) => !userIDList.includes(member.userID))
    },
    resetCurrentMemberList(state) {
      state.currentMemberList = []
    },
    reset(state) {
      Object.assign(state, {
        groupList: [],
        currentMemberList: [],
        createGroupModelVisible: false
      })
    }
  },
  actions: {
    deleteGroupMemberList(context, data) {
      context.commit('deleteGroupMemberList', [...data])
    },
    updateGroupNotification(context, data) {
      context.commit('updateGroupNotification', data)
    },
    updateGroupList(context, groupList) {
      context.commit('updateGroupList', [...groupList])
      // context.commit('updateGroupCount', groupList.length)
    },
    getGroupMemberList(context, groupID) {
      tim.getGroupMemberList({
        groupID: groupID,
        offset: context.state.currentMemberList.length,
        count: 30
      }).then((imResponse) => {
        context.commit('updateCurrentMemberList', imResponse.data.memberList)
        return imResponse
      })
    },
  }
}

export default groupModules
