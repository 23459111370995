import tim from '../../tim/tim';
import TIM from "tim-js-sdk";
const user = {
    state: {
        currentUserProfile: {},
        isLogin: false,
        isSDKReady: false, // TIM SDK 是否 ready
        userID: 0,
        userSig: "",
        sdkAppID: 0,
        roomData: {},
        localUser:{},
        focus: false,
        countTimer:null,
        token: '',
        improveUserInformation:false,
        showdialog:false,
    },
    mutations: {
        UPDATE_SHOWDIALOG(state, show){
            state.showdialog = show;
        },
        updateLocalUser(state, user) {
            state.localUser = user;
        },
        focusChange(state, sta) {
            state.focus = sta;
        },
        updateCurrentUserProfile(state, userProfile) {
            state.currentUserProfile = Object.assign( state.currentUserProfile,userProfile);
        },
        toggleIsLogin(state, isLogin) {
            state.isLogin = typeof isLogin === "undefined" ? !state.isLogin : isLogin;
        },
        toggleIsSDKReady(state, isSDKReady) {
            state.isSDKReady =
                typeof isSDKReady === "undefined"
                    ? !state.isSDKReady
                    : isSDKReady;
        },
        resetLogin(state) {
            state=Object.assign(state, {
                currentUserProfile: {},
                isLogin: false,
                isSDKReady: false // TIM SDK 是否 ready
            });
        },
        GET_USER_INFO(state, payload) {
            state.userID = payload.userID;
            state.userSig = payload.userSig;
            state.sdkAppID = payload.sdkAppID;
            state.roomData = payload.roomData;
        },
        SET_USER_TOKEN(state,token){
            console.log(token)
            state.token = token
        },
        SET_USER_INFO(state,improveUserInformation){
            console.log(improveUserInformation)
            state.improveUserInformation = improveUserInformation
        }
    },
    getters: {
        getCurrentIdentify: state => {
            console.log(state.improveUserInformation)
          if (!state.improveUserInformation) {
            return true
          }
          return state.improveUserInformation
        }
    },
    actions: {
        reset(context) {
            context.commit('resetLogin')
        },
        toggleIsLogin(context, isLogin) {
            context.commit('toggleIsLogin',isLogin)
        },
        focusChange(context, data) {
            context.commit('focusChange',data)
        },
        toggleIsSDKReady(context, isSDKReady) {
            context.commit('toggleIsSDKReady',isSDKReady)
            if(!isSDKReady){
                clearInterval(context.state.countTimer);
            }
        },
        Login(context, data) {
            context.commit("GET_USER_INFO", {
                userID: data.user.user.imId,
                userSig: data.user.sig,
                sdkAppID: data.user.sdkappid,
                roomData: data.room
            });
            context.commit('updateLocalUser', {...data.user});
            tim.login({
                userID: data.user.user.imId,
                userSig: data.user.sig
            }).then(() => {
                context.commit("toggleIsLogin", true);
                context.dispatch('SetTried',true);
                // context.commit("startComputeCurrent");
            }).catch((err)=>{
                context.dispatch('SetTried',true);
            });
        },
        syncUserProfile(context, user) {
            // const imUser = context.state.currentUserProfile;
            // if (imUser.nick != user.nick||imUser.avatar != user.avatar) {
                tim.updateMyProfile({
                    nick:user.nick,
                    // avatar:user.avatar,
                }).then(({ data }) => {
                    context.commit("updateCurrentUserProfile", {...data});
                });
            // }
        },
        searchGroup({ dispatch, state }) {
            tim.updateMyProfile({
                //先更新昵称再加入群
                nick: state.currentUserProfile.nick,
                avatar: state.currentUserProfile.avatar,
                profileCustomField: [
                    {
                        key: "Tag_Profile_Custom_Realname",
                        value: state.currentUserProfile.realname
                    },
                    {
                        key: "Tag_Profile_Custom_Work",
                        value: state.currentUserProfile.workplace
                    }
                ]
            }).then(data => {
                let roomid = state.roomData.imId;
                let promise = tim.searchGroupByID(roomid);
                promise
                    .then(function(imResponse) {
                        dispatch("updateGroupCurrent", imResponse.data.group);
                        dispatch("joinGroup");
                    })
                    .catch(function(imError) {
                        dispatch("createGroup");
                    });
            });
        },
        joinGroup({ dispatch, state, commit}) {
            let roomid = state.roomData.imId;
            if(localStorage['vGroup'+roomid]&&localStorage['vGroup'+roomid]==="1"){//避免重复join
                dispatch('checkoutConversation', "GROUP" + roomid);
                // dispatch("getGroupProfile", roomid);
                state.countTimer=setInterval(() => {
                    dispatch("getGroupProfile", roomid);
                }, 60000)
            }else{
                let promise = tim.joinGroup({
                    groupID: roomid
                });
                promise.then(res => {
                    const data = res.data; // 群组信息
                    switch (data.status) {
                        case TIM.TYPES.JOIN_STATUS_WAIT_APPROVAL: // 等待管理员同意
                            break;
                        case TIM.TYPES.JOIN_STATUS_SUCCESS: // 加群成功
                        case TIM.TYPES.JOIN_STATUS_ALREADY_IN_GROUP: // 已经在群中
                            dispatch('checkoutConversation', "GROUP" + roomid)
                            if (data.status == TIM.TYPES.JOIN_STATUS_SUCCESS){
                                dispatch("updateGroupCurrent", data.group);
                            }else{
                                // dispatch("getGroupProfile", roomid);
                            }
                            localStorage['vGroup'+roomid]="1";
                            state.countTimer=setInterval(() => {
                                dispatch("getGroupProfile", roomid);
                            }, 60000)
                            break;
                        default:
                            break;
                    }
                })
                .catch(function(imError) {
                    console.log('加入群组失败：',imError);
                    console.warn("joinGroup error:"); // 申请加群失败的相关信息
                    commit("toggleIsLogin", false);//如果join失败，也不显示聊天界面，而显示重新登录
                    dispatch('logout');
                    dispatch('showMessage', {
                        message: imError,
                        type: 'error'
                    })
                });
            }
        },
        getGroupProfile(context,groupid) {
            if(context.state.isSDKReady){
                let promise = tim.getGroupProfile({ groupID: groupid});
                promise.then(function (imResponse) {
                    context.dispatch('updateGroupCurrent', imResponse.data.group);
                }).catch(function (imError) {
                    console.warn('getGroupProfile error:', imError); // 获取群详细资料失败的相关信息
                });
            }
        },
        createGroup({ dispatch, commit, state }) {
            let roomid = state.roomData.imId;
            let promise = tim.createGroup({
                type: TIM.TYPES.GRP_CHATROOM, // GRP_PUBLIC
                name: roomid,
                groupID: roomid,
                joinOption: TIM.TYPES.JOIN_OPTIONS_FREE_ACCESS
            });
            promise
                .then(function(imResponse) {
                    // 创建成功
                    const group = imResponse.data.group; // 群组信息
                    dispatch("checkoutConversation", "GROUP" + group.groupID);
                })
                .catch(function(imError) {
                    // console.warn("createGroup error:", imError); // 创建群组失败的相关信息
                    dispatch('showMessage', {
                        message: imError,
                        type: 'error'
                    })
                });
        },
        logout(context) {
            // 若有当前会话，在退出登录时已读上报
            // if (context.rootState.conversation.currentConversation.conversationID) {
            //     tim.setMessageRead({
            //         conversationID:context.rootState.conversation.currentConversation.conversationID
            //     });
            // }
            if(context.state.isSDKReady){//如果是连接状态，则退出登录
                tim.logout().then(() => {
                    // context.commit("stopComputeCurrent");
                    context.commit("resetLogin");
                }).catch((err)=>{
                });
            }
        },
    }
};

export default user;
