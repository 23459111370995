// all scripts start at here
// share plugin
import "../plugins/share";

// ajax paginator loader plugin
// import "../plugins/load-piece";

// lazy load plugin
import '../plugins/load-on-sight';

// load comments when switch to this tab
import "./helpers/comments-in-tab";

// click to switch video
import "./helpers/switch-player";

$(function() {
    //首页ajax加载数据
    $(".load-on-sight").loadOnSight({
        callback: function () {
            $(this).animate({
                'opacity': 1
            });
        }
    });

    // enable share plugin
    $(".tinyshare").tinyshare();
});