/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import '../../bootstrap-material-design';
import 'bootstrap-material-design';
// import '../plugins';

// include arrive to watch dynamically created elements generated by vuejs
// import 'arrive';

// snack and toast message
import 'snackbarjs';

// begin material-kit scripts
import 'material-kit/assets/js/material-kit';

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
import "../components/video/vue";
