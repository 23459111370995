import tim from '../../tim/tim'
import TIM from 'tim-js-sdk'
const conversationModules = {
  state: {
    currentConversation: {},
    currentMessageList: [],
    nextReqMessageID: '',
    isCompleted: false, // 当前会话消息列表是否已经拉完了所有消息
    conversationList: [],
    thisgroup:{
      memberNum:0,
      ownerID:""
    },
    height:'60vh',
    notInGroup:false
  },
  getters: {
    toAccount: state => {
      if (!state.currentConversation || !state.currentConversation.conversationID) {
        return ''
      }
      switch (state.currentConversation.type) {
        case 'C2C':
          return state.currentConversation.conversationID.replace('C2C', '')
        case 'GROUP':
          return state.currentConversation.conversationID.replace('GROUP', '')
        default:
          return state.currentConversation.conversationID
      }
    },
    currentConversationType: state => {
      if (!state.currentConversation || !state.currentConversation.type) {
        return ''
      }
      return state.currentConversation.type
    },
    totalUnreadCount: state => {
      const result = state.conversationList.reduce((count, conversation) => {
        // 当前会话不计算总未读
        // if (!store.getters.hidden && state.currentConversation.conversationID === conversation.conversationID) {
        //   return count
        // }
        // return count + conversation.unreadCount
      }, 0)
      // titleNotify(result)
      return result
    },
    // 用于当前会话的图片预览
    imgUrlList: state => {
      return state.currentMessageList
        .filter(message => message.type === TIM.TYPES.MSG_IMAGE && !message.isRevoked) // 筛选出没有撤回并且类型是图片类型的消息
        .map(message => message.payload.imageInfoArray[0].url)
    },
  },
  mutations: {
    /**
     * 更新当前会话
     * 调用时机: 切换会话时
     */
    updateCurrentConversation(state, conversation) {
      state.currentConversation = conversation
      state.currentMessageList = []
      state.nextReqMessageID = ''
      state.isCompleted = false
    },
    /**
     * 更新会话列表
     * 调用时机：触发会话列表更新事件时。CONVERSATION_LIST_UPDATED
     */
    updateConversationList(state, conversationList) {
      state.conversationList = conversationList
    },
    updateConversationInfo(state,thisgroup){
      state.thisgroup=thisgroup;
    },
    /**
     * 重置当前会话
     * 调用时机：需要重置当前会话时，例如：当前会话是一个群组，正好被踢出群时（被踢群事件触发），重置当前会话
     */
    resetCurrentConversation(state) {
      state.currentConversation = {}
    },
    /**
     * 将消息插入当前会话列表
     * 调用时机：收/发消息事件触发时
     */
    pushCurrentMessageList(state, data) {
      // 还没当前会话，则跳过
      if (!state.currentConversation.conversationID) {
        return
      }
      if (Array.isArray(data)) {
        // 筛选出当前会话的消息
        const result = data.filter(item => item.conversationID === state.currentConversation.conversationID)
        state.currentMessageList = [...state.currentMessageList, ...result]
      } else if (data.conversationID === state.currentConversation.conversationID) {
        state.currentMessageList = [...state.currentMessageList, data]
      }
    },
    /**
     * 从当前消息列表中删除某条消息
     */
    removeMessage(state, message) {
      const index = state.currentMessageList.findIndex(({ ID }) => ID === message.ID)
      if (index >= 0) {
        state.currentMessageList.splice(index, 1)
      }
    },
    reset(state) {
      Object.assign(state, {
        currentConversation: {},
        currentMessageList: [],
        nextReqMessageID: '',
        isCompleted: false, // 当前会话消息列表是否已经拉完了所有消息
        conversationList: []
      })
    },
    updateGroupCurrent(state,group) {
      state.thisgroup=group;
      // console.log(state.thisgroup,5555)
    },
    setHeight(state,height){
      state.height=height;
    },
    setNotInGroup(state,data){
      state.notInGroup=data;
    }
  },
  actions: {
    /**
     * 获取消息列表
     * 调用时机：打开某一会话时或下拉获取历史消息时
     */
    updateConversationInfo(context,thisgroup){
      context.commit('updateConversationInfo', thisgroup)
    },
    updateConversationList(context, list) {
      context.commit('updateConversationList', [...list])
    },
    removeMessage(context, message) {
      context.commit('removeMessage', message)
    },
    resetCurrentConversation(context) {
      context.commit('resetCurrentConversation')
    },
    pushCurrentMessageList(context, data) {
      context.commit('pushCurrentMessageList', data)
    },
    updateGroupCurrent({commit},group){
      commit('updateGroupCurrent',group)
    },
    setHeight({commit},height){
      commit('setHeight',height)
    },
    getMessageList(context, conversationID) {
      if (context.state.isCompleted) {
        context.dispatch('showMessage', {
          message: '已经没有更多的历史消息了哦',
          type: 'info'
        })
        return
      }
      const { nextReqMessageID, currentMessageList } = context.state
      tim.getMessageList({ conversationID, nextReqMessageID, count: 15 }).then(imReponse => {
        // 更新messageID，续拉时要用到
        context.state.nextReqMessageID = imReponse.data.nextReqMessageID
        context.state.isCompleted = imReponse.data.isCompleted
        // 更新当前消息列表，从头部插入
        const result = imReponse.data.messageList.filter(item => !(item.type=== 'TIMCustomElem'&&item.payload.data==="group_create"))
        // const result = imReponse.data.messageList
        // console.log(result,232323)
        context.state.currentMessageList = [...result, ...currentMessageList]
      })
    },
    /**
     * 切换会话
     * 调用时机：切换会话时
     */
    checkoutConversation(context, conversationID) {
      context.commit('resetCurrentMemberList')
      // 1.切换会话前，将切换前的会话进行已读上报
      if (context.state.currentConversation.conversationID) {
        const prevConversationID = context.state.currentConversation.conversationID
        tim.setMessageRead({ conversationID: prevConversationID })
      }
      // 2.待切换的会话也进行已读上报
      tim.setMessageRead({ conversationID })
      // 3. 获取会话信息
      tim.getConversationProfile(conversationID).then(({ data }) => {
        // console.log('获取对话信息成功：',data.conversation);
        //首次刷新对话的群组信息
        context.commit('updateGroupCurrent', data.conversation.groupProfile);
        // 3.1 更新当前会话
        context.commit('updateCurrentConversation', data.conversation)
        // 3.2 获取消息列表
        context.dispatch('getMessageList', conversationID)
        // 3.3 拉取第一页群成员列表
          if (data.conversation.type === TIM.TYPES.CONV_GROUP) {

              context.dispatch('updateGroupNotification', data.conversation.groupProfile.notification);
          return context.dispatch('getGroupMemberList', data.conversation.groupProfile.groupID)
        }
        return Promise.resolve()
      }).catch((imError)=>{//0319失败第一追加一次
        console.log('获取对话信息失败：',imError);
        if(imError=='Error: no permission'){
          var imId=conversationID.slice(5);//截掉conversationID前面的group标记
          context.commit('setNotInGroup',true);
          localStorage.removeItem('vUser'+imId);
          localStorage.removeItem('vGroup'+imId);
        }else{
          tim.getConversationProfile(conversationID).then(({ data }) => {
            // 3.1 更新当前会话
            context.commit('updateCurrentConversation', data.conversation)
            // 3.2 获取消息列表
            context.dispatch('getMessageList', conversationID)
          }).catch((imError)=>{//0319失败第一追加一次
            if(imError=='Error: no permission'){
              var imId=conversationID.slice(5);//截掉conversationID前面的group标记
              context.commit('setNotInGroup',true);
              localStorage.removeItem('vUser'+imId);
              localStorage.removeItem('vGroup'+imId);
            }
          });
        }
      });
    },
    checkoutConversationNew(context, conversationID) {
      tim.getConversationProfile(conversationID).then(({ data }) => {
        // 3.1 更新当前会话
        context.commit('updateCurrentConversation', data.conversation)
        // 3.2 获取消息列表
        context.dispatch('getMessageList', conversationID)
        return Promise.resolve()
      }).catch((imError)=>{});
    },
  }
}

export default conversationModules
