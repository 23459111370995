var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("top-bar", {
    attrs: { endpoint: _vm.endpoint, site: _vm.site },
    on: {
      messages: _vm.messages,
      errors: _vm.errors,
      "logged-in": _vm.signedIn,
      "logged-out": _vm.signedOut,
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (sp) {
          return [
            _c("theme-bs4-material", {
              attrs: {
                pos: "sticky-top",
                fluid: false,
                user: sp.user,
                links: sp.links,
                cooldown: sp.cooldown,
                captcha: sp.captcha,
                closefeedback: sp.closefeedback,
              },
            }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }