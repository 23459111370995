(function($){

	var qqApi = {
            lower: "//3gimg.qq.com/html5/js/qb.js",
            higher: "//jsapi.qq.com/get?api=app.share"
        },
        wxApi = "//res.wx.qq.com/open/js/jweixin-1.0.0.js",
        authApi = "//qp.91360.com/api/wechat/auth", //获取认证的地址
        // qrApi = "//pan.baidu.com/share/qrcode?w=240&h=240&url=";
        // qrApi = "http://qr.topscan.com/api.php?text=";
        // qrApi = "https://paya.swiftpass.cn/pay/qrcode?uuid=";
        qrApi = "https://api.qrserver.com/v1/create-qr-code/?data=";

    var bLevel = {
        qq: {forbid: 0, lower: 1, higher: 2},
        uc: {forbid: 0, allow: 1}
    };

    var ucAppList = {
        weibo: ['kSinaWeibo', 'SinaWeibo', 11, '新浪微博'],
        weixin: ['kWeixin', 'WechatFriends', 1, '微信好友'],
        weixinFriend: ['kWeixinFriend', 'WechatTimeline', 8, '微信朋友圈'],
        qq: ['kQQ', 'QQ', 4, 'QQ好友'],
        qzone: ['kQZone', 'QZone', 3, 'QQ空间']
	};

    var UV = navigator.appVersion,
    	UA = navigator.userAgent,
    	isqqBrowser = (UV.split("MQQBrowser/").length > 1) ? bLevel.qq.higher : bLevel.qq.forbid,
    	isucBrowser = (UV.split("UCBrowser/").length > 1) ? bLevel.uc.allow : bLevel.uc.forbid,
    	isWeixin = /MicroMessenger/.test(UA),
    	OS = ((UA.indexOf("iPhone") > -1 || UA.indexOf("iPod") > -1)) ? "iPhone" : "Android";

    var version = {
        uc: "",
        qq: ""
    };

    var replaces = {
		weibo: function(e,t,n,o){return"http://service.weibo.com/share/share.php?appkey=&title="+encodeURIComponent(e+(o?" "+o:""))+"&url="+encodeURIComponent(t)+"&source=&retcode=0&pic="+encodeURIComponent(n)},
		qq:function(e,t,n,o){return"http://connect.qq.com/widget/shareqq/index.html?url="+encodeURIComponent(t)+"&desc="+encodeURIComponent(e+(o?" "+o:""))+"&pics="+encodeURIComponent(n)+"&site="},
		weixin:function(e,t){return t},
		qzone:function(e,t,n,o){return"http://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url="+encodeURIComponent(t)+"&title="+encodeURIComponent(e)+"&pics="+encodeURIComponent(n)+"&summary="+encodeURIComponent(o)+"&desc="},
		douban:function(e,t,n,o){return"https://www.douban.com/share/service?image="+encodeURIComponent(n)+"&href="+encodeURIComponent(t)+"&name="+encodeURIComponent(e)+"&text="+encodeURIComponent(o)}
	};

	var getVersion = function (c) {
        var a = c.split("."), b = parseFloat(a[0] + "." + a[1]);
        return b
    };

    var loadQQWXAPI = function () {
        if (isqqBrowser && !isWeixin) {
            var b = (version.qq < 5.4) ? qqApi.lower : qqApi.higher;
            $('body').append('<script type="text/javascript" src="'+b+'"></script>');
        }else if(isWeixin){
        	$('body').append('<script type="text/javascript" src="'+wxApi+'"></script>');
        }
    };

    var wxParsed = false, // 对于微信分享，接口解析一次即可
    	wxGuide = '//a1.cdn.91360.com/cms/bs3/img/wx-guide.png',
    	wxOverlay = function() {
	    	$('<div />').css({
	    		position: 'absolute',
	    		left: 0,
	    		top: 0,
	    		bottom: 0,
	    		right: 0,
	    		backgroundColor: 'rgba(0,0,0,.5)',
	    		backgroundImage: 'url('+wxGuide+')',
	    		backgroundPosition: 'top right',
	    		backgroundRepeat: 'no-repeat',
	    		zIndex: 9999
	    	}).on('click', function(){$(this).remove()}).appendTo($('body'));
	    };

	var popQrcode = function(e){
		var $dlg = $('.modal-qrcode');
		if($dlg.length < 1) {
			$dlg = $('<div class="modal fade modal-qrcode lecture-share-modal" role="dialog">' +
			'	<div class="modal-dialog modal-sm">' +
			'		<div class="modal-content">' +
			'			<div class="modal-header">' +
			'			<h5 class="modal-title">扫描二维码</h5>' +
        	'				<button type="button" class="close" data-dismiss="modal">' +
        	'					<span aria-hidden="true">&times;</span>' +
        	'				</button>' +
        	'			</div>' +
        	'			<div class="modal-body text-center ">' +
        	'				<img src="'+qrApi+encodeURIComponent(e)+'" alt="二维码加载失败..." style="width:100%">' +
        	'			</div>' +
        	'		</div>' +
        	'	</div>' +
        	'</div>');
		}
		$dlg.modal('show');
	};

    // init once
    version.qq = isqqBrowser ? getVersion(UV.split("MQQBrowser/")[1]) : 0;
    version.uc = isucBrowser ? getVersion(UV.split("UCBrowser/")[1]) : 0;
    if ((isqqBrowser && version.qq < 5.4 && OS == "iPhone") || (isqqBrowser && version.qq < 5.3 && OS == "Android")) {
        isqqBrowser = bLevel.qq.forbid
    } else {
        if (isqqBrowser && version.qq < 5.4 && OS == "Android") {
            isqqBrowser = bLevel.qq.lower
        } else {
            if (isucBrowser && ((version.uc < 10.2 && OS == "iPhone") || (version.uc < 9.7 && OS == "Android"))) {
                isucBrowser = bLevel.uc.forbid
            }
        }
    }
    loadQQWXAPI();
    // init done.

	function shareIt($el, config){
		if(!$el.length) return false;

	    config = config || {};

	    this.$el = $el;
	    this.item = config.item || '.share-it';

	    this.url = config.url || document.location.href || '';
	    this.title = config.title || document.title || '';
	    this.desc = config.desc || $('meta[name="description"]').attr('content') || '';
	    this.img = config.img || $('img').length && $('img').eq(0).attr('src') || '';
	    this.img_title = config.img_title || document.title || '';
	    this.from = config.from || window.location.host || '';

	    this.init = function () {
	    	this.initWeChat(); //微信API在调用实例时就需要加载，而不是在点击时
	        this.bind();
	    };

	    this.appShare = function (to_app) {
	        var title = this.title, url = this.url, desc = this.desc, img = this.img, img_title = this.img_title, from = this.from;
	        if (isucBrowser) {
	            to_app = to_app == '' ? '' : (OS == 'iPhone' ? ucAppList[to_app][0] : ucAppList[to_app][1]);
	            if(!to_app){
	            	this.webShare(to_app);
	            }else{
	            	if (to_app == 'qzone') {
		                B = "mqqapi://share/to_qzone?src_type=web&version=1&file_type=news&req_type=1&image_url="+img+"&title="+title+"&description="+desc+"&url="+url+"&app_name="+from;
		                k = document.createElement("div"), k.style.visibility = "hidden", k.innerHTML = '<iframe src="' + B + '" scrolling="no" width="1" height="1"></iframe>', document.body.appendChild(k), setTimeout(function () {
		                    k && k.parentNode && k.parentNode.removeChild(k)
		                }, 5E3);
		            }
		            if (typeof(ucweb) != "undefined") {
		                ucweb.startRequest("shell.page_share", [title, title, url, to_app, "", "@" + from, ""])
		            } else {
		                if (typeof(ucbrowser) != "undefined") {
		                    ucbrowser.web_share(title, title, url, to_app, "", "@" + from, '')
		                } else {
		                	this.webShare(to_app);
		                }
		            }
	            }
	        } else {
	            if (isqqBrowser && !isWeixin) {
	                to_app = to_app == '' ? '' : ucAppList[to_app][2];
	                var ah = {
	                    url: url,
	                    title: title,
	                    description: desc,
	                    img_url: img,
	                    img_title: img_title,
	                    to_app: to_app,//微信好友1,腾讯微博2,QQ空间3,QQ好友4,生成二维码7,微信朋友圈8,啾啾分享9,复制网址10,分享到微博11,创意分享13
	                    cus_txt: "请输入此时此刻想要分享的内容"
	                };
	                if(!to_app){
	                	this.webShare(to_app);
	                }else{
	                	ah = to_app == '' ? '' : ah;
		                if (typeof(browser) != "undefined") {
		                    // if (typeof(browser.app) != "undefined" && isqqBrowser == bLevel.qq.higher) {
		                        browser.app.share(ah);
		                    // }
		                } else {
		                    if (typeof(window.qb) != "undefined"/* && isqqBrowser == bLevel.qq.lower*/) {
		                        window.qb.share(ah);
		                    } else {
		                    	this.webShare(to_app);
		                    }
		                }
	                }
	            } else if (isWeixin && (to_app == 'weixin' || to_app == 'qq')) {
	            	wxOverlay();
	            } else {
	            	this.webShare(to_app);
	            }
	        }
	    };

	    this.webShare = function(to_app) {
			if(to_app && replaces[to_app]) {
				var webapi = replaces[to_app](this.title, this.url, this.img, this.desc);

				if("weixin" == to_app) {
					popQrcode(this.url);
				}else{
					window.open(webapi);
				}
			}
			return this;
		};

	    this.initWeChat = function() {
	    	if(!isWeixin || wxParsed) return false;
    		var link = window.location.href,
	    		title = this.title,
	    		img = this.img,
	    		desc = this.desc;

			$.getJSON(authApi+'?callback=?', {link: link}, function(json){
				wx.config({
					debug: false,
					appId: json.appid,
					timestamp: json.ts,
					nonceStr: json.wild,
					signature: json.signature,
					jsApiList: ['onMenuShareTimeline', 'onMenuShareAppMessage', 'onMenuShareQQ', 'onMenuShareQZone']
				});
				wx.ready(function(){
				    // 获取“分享到朋友圈”按钮点击状态及自定义分享内容接口
				    wx.onMenuShareTimeline({
				        title: title, // 分享标题
				        link: link,
				        imgUrl: img
				    });
				    // 获取“分享给朋友”按钮点击状态及自定义分享内容接口
				    wx.onMenuShareAppMessage({
				        title: title,
				        desc: desc,
				        link: link,
				        imgUrl: img,
				        type: 'link', // 分享类型,music、video或link，不填默认为link
				    });
				    wx.onMenuShareQQ({
					    title: title, // 分享标题
					    desc: desc, // 分享描述
					    link: link, // 分享链接
					    imgUrl: img
					});
					wx.onMenuShareQZone({
					    title: title, // 分享标题
					    desc: desc, // 分享描述
					    link: link, // 分享链接
					    imgUrl: img
					});
				});
				wxParsed = true; //解析完毕
			});
	    };

	    this.bind = function(){
	    	var self = this;
	    	self.$el.find(self.item).on('click', function(){
	    		var app = $(this).data('app');
	    		if(!app) return false;
	    		self.appShare(app);
	    	});
	    };

	    this.init();
	}

	$.fn.tinyshare = function(){
		this.each(function(){
			var $this = $(this),
				config = {
					title: $this.data('title'),
					url: $this.data('url'),
					desc: $this.data('desc'),
					img: $this.data('img'),
					img_title: $this.data('img-title'),
					from: $this.data('from')
				};
			return new shareIt($this, config);
		});
	};
})(jQuery);
