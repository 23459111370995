var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "position-relative", class: _vm.elClass },
    [
      _vm.loading
        ? _c("div", { staticClass: "init-box ratio2_1 bg-light" }, [
            _c(
              "div",
              {
                staticClass:
                  "box-content d-flex justify-content-center align-items-center",
              },
              [
                _vm.passwdResource
                  ? _c("div", [
                      _c("div", { staticClass: "card" }, [
                        _c("div", { staticClass: "card-body" }, [
                          _c("h4", { staticClass: "card-title" }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.passwdResource.message) +
                                "\n                        "
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "form",
                            {
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                  return _vm.authPassword(_vm.passwdResource)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "form-group" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.password,
                                      expression: "password",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "password",
                                    placeholder: "请输入密码",
                                    required: "",
                                  },
                                  domProps: { value: _vm.password },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.password = $event.target.value
                                    },
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _vm._m(0),
                            ]
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.bookResource
                  ? _c("div", [
                      _c("div", { staticClass: "card" }, [
                        _c("div", { staticClass: "card-body" }, [
                          _c("h4", { staticClass: "card-title" }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.bookResource.message) +
                                "\n                        "
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "form",
                            {
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                  return _vm.bookInfo(_vm.bookResource)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "form-group" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.book.realname,
                                      expression: "book.realname",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    placeholder: "请输入姓名",
                                    required: "",
                                  },
                                  domProps: { value: _vm.book.realname },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.book,
                                        "realname",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "form-group" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.book.workplace,
                                      expression: "book.workplace",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    placeholder: "请输入工作单位",
                                    required: "",
                                  },
                                  domProps: { value: _vm.book.workplace },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.book,
                                        "workplace",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "text-center" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-sm btn-round btn-default",
                                    attrs: { type: "reset" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.reset()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    重置\n                                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-sm btn-round btn-info",
                                    attrs: { type: "submit" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    提交\n                                "
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ])
                  : _vm.authorize
                  ? _c("h3", { staticClass: "title" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.authorize.message) +
                          "\n            "
                      ),
                    ])
                  : _vm.ending
                  ? _c("h3", { staticClass: "py-5" }, [
                      _vm._v(_vm._s(_vm.endtext)),
                    ])
                  : _vm.countDown.cd
                  ? _c("h1", { staticClass: "title" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.formatedCountDown) +
                          "\n            "
                      ),
                    ])
                  : _c(
                      "svg",
                      {
                        staticClass: "spinner",
                        attrs: {
                          width: "65px",
                          height: "65px",
                          viewBox: "0 0 66 66",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                      },
                      [
                        _c("circle", {
                          staticClass: "path",
                          attrs: {
                            fill: "none",
                            "stroke-width": "6",
                            "stroke-linecap": "round",
                            cx: "33",
                            cy: "33",
                            r: "30",
                          },
                        }),
                      ]
                    ),
              ]
            ),
          ])
        : _c("d-player", {
            ref: "player",
            attrs: { options: _vm.dpOpts },
            on: {
              play: _vm.videoIsPlaying,
              pause: _vm.videoIsPaused,
              ended: _vm.videoIsEnded,
            },
          }),
      _vm._v(" "),
      _vm.paymentOverlay
        ? _c(
            "div",
            {
              staticClass:
                "card-img-overlay video-overlay d-flex justify-content-center align-items-center",
            },
            [
              _c("payment-code", {
                attrs: {
                  "el-class": "",
                  "call-from": _vm.dpOpts.video.orderApi,
                },
                on: { closed: _vm.whenPaymentClosed },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-center" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-sm btn-round btn-default",
          attrs: { type: "reset" },
        },
        [
          _vm._v(
            "\n                                    重置\n                                "
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-sm btn-round btn-info",
          attrs: { type: "submit" },
        },
        [
          _vm._v(
            "\n                                    提交\n                                "
          ),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }