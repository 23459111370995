/**
 * ajax 提交表单
 *
 * @param {jQuery} $form
 * @param {Function} resolve called after ajax return success
 * @param {Function} reject called after ajax return errors
 */
function ajaxForm($form, resolve, reject) {
    $form.on('submit', function () {
        const $this = $(this);
        let data = new FormData(this);

        clearErrors($this);

        axios({
            method: $this.attr('method') ? $this.attr('method') : 'post',
            url: $this.attr('action'),
            data: data
        }).then(resp => {
            resolve(resp.data);
        }).catch(err => {
            const resp = err.response;

            if (resp.data.errors) {
                whenFormHasErrors($this, resp.data.errors);
            }

            reject(resp);
        });

        return false;
    });
}

/**
 * 消息提示
 *
 * @param {String} message
 * @param {String} style
 */
function toast(message, style) {
    $.snackbar({
        content: message,
        style: '' + (style ? ' ' + style : '')
    });
}

/**
 * 填充消息
 *
 * @param {jQuery} $form
 * @param {Object} errors
 */
function whenFormHasErrors($form, errors) {
    if ($form.length && errors) {
        _.each(errors, (errs, key) => {
            if ($form.find('[name="' + key + '"]').length) {
                if (key == 'avatar_location') {
                    key = 'avatar_type';
                }

                let $input = $form.find('[name="' + key + '"]');

                if ($input.is(':radio') || $input.is(':checkbox')) {
                    $input = $input.closest('.bmd-form-group').find('.form-check').last();
                }

                $input.addClass('is-invalid').closest('.bmd-form-group').addClass('has-danger');

                if (!$input.siblings('.invalid-feedback').length) {
                    $('<small />')
                        .addClass('invalid-feedback')
                        .addClass('text-right')
                        .insertAfter($input);
                }

                $input.siblings('.invalid-feedback').text(errs.join('\n'));
            }
        });
    }
}

function clearErrors($form) {
    $form.find('.has-danger').removeClass('has-danger');
    $form.find('.is-invalid').removeClass('is-invalid');
}

/**
 * 重新刷新csrf token
 *
 * @param {String} token
 */
function refreshCsrfToken(token) {
    // for global axios
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token;

    // for jQuery
    window.$.ajaxSetup({
        headers: {
            "X-CSRF-TOKEN": token
        }
    });

    // for meta
    window.$('meta[name="csrf-token"]').attr('content', token);
}

const swalWithBootstrapButtons = swal.mixin({
    confirmButtonClass: 'btn btn-success',
    cancelButtonClass: 'btn btn-danger',
    buttonsStyling: false,
})

const InputErrorHelper = {
    check(errors, key) {
        return errors && _.has(errors, key);
    },

    feedback(errors, key, defaults) {
        if (errors && _.has(errors, key)) {
            return errors[key].join(', ');
        }

        return defaults;
    }
}

/**
 * 格式秒数为 HH:MM:SS
 *
 * @param {Integer} seconds
 */
function formatToHHMMSS(sec) {
    let secondNumber = parseInt(sec, 10); // don't forget the second param
    let hours = Math.floor(secondNumber / 3600);
    let minutes = Math.floor((secondNumber - (hours * 3600)) / 60);
    let seconds = secondNumber - (hours * 3600) - (minutes * 60);

    if (hours < 10) {
        hours = "0" + hours;
    }

    if (minutes < 10) {
        minutes = "0" + minutes;
    }

    if (seconds < 10) {
        seconds = "0" + seconds;
    }

    return hours + ':' + minutes + ':' + seconds;
}

export {
    ajaxForm,
    toast,
    whenFormHasErrors,
    swalWithBootstrapButtons,
    InputErrorHelper,
    refreshCsrfToken,
    formatToHHMMSS
}
