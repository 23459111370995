import bus from "../../components/bus";

$(function () {
    $('.switch-player').on('click', function (e) {
        e.preventDefault();

        let $this = $(this),
            api = $this.data('api'),
            isStream = $this.data('is-stream');
        
        if ($this.hasClass('active')) {
            return false;
        }

        bus.$emit('switch-player', {api: api, isStream: isStream ? true : false})
        
        $this.addClass('active')
            .closest('li')
            .siblings()
            .find('.switch-player')
            .removeClass('active');
    }).eq(0).trigger('click');
});


