<template>
    <div class="nav-breadcrumb card-breadcrumb">
        <ol class="breadcrumb">
            <li v-if="category.name" class="breadcrumb-item">
                <a :href="category.url">{{ category.name }}</a>
            </li>
            <li class="breadcrumb-item active">
                浏览次数: <span class="text-primary">{{ viewData }}</span>
            </li>
            <li v-if="expert" class="breadcrumb-item active">
                讲师: <span class="text-primary">{{ expert }}</span>
            </li>
            <li v-if="startDate" class="breadcrumb-item active">
                开始时间: <span class="text-primary">{{ startDate }}</span>
            </li>
        </ol>
    </div>
</template>

<script>
export default {
    props: {
        category: {
            required: false,
            type: Object,
            default() {
                return {
                    name: '',
                    url: ''
                }
            }
        },
        expert: {
            required: false,
            type: String,
            default: ""
        },
        startDate: {
            required: false,
            type: String,
            default: ""
        },
        views: {
            required: false,
            type: String,
            default: "0"
        },
        viewsUrl: {
            required: false,
            default: null
        }
    },

    data() {
        return {
            viewData: this.views,
            people: ""
        };
    },
    mounted: function() {
        // this.initviews();
        // setInterval(() => {
        //     this.initviews();
        // }, 5000 * 60);
    },
    methods: {
        initviews() {
            axios
                .get(this.viewsUrl)
                .then(resp => this.viewData = resp.data.view);
        }
    }
};
</script>
