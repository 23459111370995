import { BaseService } from "./BaseService";
import { CommentApi } from "../helpers/config";

export class CommentService extends BaseService
{
    pagination(url, page = 1) {
        return this.post(url, {page: page});
    }

    create(data) {
        return this.post(CommentApi.create, data);
    }

    reply(comment, data) {
        return this.post(CommentApi.reply.replace('{comment}', comment.id), data);
    }
}
