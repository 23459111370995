import bus from "../components/bus";

/**
 * 基础服务
 * 定义 http
 * 以及基本的 异常处理
 */
export class BaseService {

    constructor() {
        // if (new.target === BaseService) {
        //     throw new Error('you should extend this class instead of initliazing it.');
        // }

        this.http = axios;

        this.bus = bus;
    }

    /**
     * Get 请求
     *
     * @param {String} url
     * @param {Object|URLSearchParams} params
     *
     * @returns {Promise}
     */
    get(url, params) {
        return new Promise((resolve, reject) => {
            this.http.get(url, {params: params})
                .then(resp => resolve(resp.data))
                .catch(err => this.catch(err, reject));
        });
    }

    /**
     * post 请求
     *
     * @param {String} url
     * @param {FormData|File|Blob|Stream|Object|String|ArrayBuffer|URLSearchParams} data
     *
     * @returns {Promise}
     */
    post(url, data) {
        return new Promise((resolve, reject) => {
            this.http.post(url, data)
                .then(resp => resolve(resp.data))
                .catch(err => this.catch(err, reject));
        });
    }

    /**
     * put 请求
     *
     * @param {String} url
     * @param {FormData|File|Blob|Stream|Object|String|ArrayBuffer|URLSearchParams} data
     *
     * @returns {Promise}
     */
    put(url, data, option) {
        return new Promise((resolve, reject) => {
            this.http.put(url, data, option)
                .then(resp => resolve(resp.data))
                .catch(err => this.catch(err, reject));
        });
    }

    /**
     * patch 请求
     *
     * @param {String} url
     * @param {FormData|File|Blob|Stream|Object|String|ArrayBuffer|URLSearchParams} data
     *
     * @returns {Promise}
     */
    patch(url, data) {
        return new Promise((resolve, reject) => {
            this.http.patch(url, data)
                .then(resp => resolve(resp.data))
                .catch(err => this.catch(err, reject));
        });
    }

    /**
     * delete 请求
     *
     * @param {String} url
     * @param {Object|URLSearchParams} params
     *
     * @returns {Promise}
     */
    delete(url, params) {
        return new Promise((resolve, reject) => {
            this.http.delete(url, {params: params})
                .then(resp => resolve(resp.data))
                .catch(err => this.catch(err, reject));
        });
    }

    /**
     * 全局异常捕获
     *
     * 处理通用异常, 然后将额外的处理 交给 reject
     * 外部通过 catch 方法执行该回调
     *
     * @param {Object} err
     * @param {Function} reject
     */
    catch(err, reject) {
        if (err.response){
            const response = err.response;

            switch (response.status) {
                case 401: // authenticate
                    // 推送未登录事件
                    this.bus.$emit('unauthenticated');
                    break;
                case 403: // authorize

                    break;
                case 419: // too many attempts

                    break;
                case 422: // unprocessed entity

                    break;
                default:
                    break;
            }

            reject(response);
        }

    }

    /**
     * 用于在独立组件之间通信的 vue 对象
     *
     * @returns {Vue}
     */
    hub() {
        return this.bus;
    }
}
