import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import blacklist from "./modules/blacklist";
import conversation from "./modules/conversation";
import friend from "./modules/friend";
import group from "./modules/group";
import Site from "./modules/site";
import user from "./modules/user";
import video from "./modules/video";
import {Message} from "element-ui";

Vue.use(Vuex)

const store = () => new Vuex.Store({
    modules: {
        blacklist,
        conversation,
        friend,
        group,
        Site,
        user,
        video
    },
    state: {
        current: Date.now(), // 当前时间
        intervalID: 0,
        message: undefined,
        notification: undefined,
        globalMessage: undefined
    },
    mutations: {
        startComputeCurrent(state){
            clearInterval(state.intervalID);
            state.intervalID = setInterval(() => {
                state.current = Date.now();
            }, 500);
        },
        stopComputeCurrent(state){
            clearInterval(state.intervalID);
            state.intervalID = 0;
        },
        showMessage(state, options) {
            if (state.message) {
                state.message.close();
            }
            state.message = Message({
                message: options.message,
                type: options.type || "success",
                duration: options.duration || 2000,
                offset: 40
            });
        },
        showNotification(state, options) {
            state.globalMessage = options;
        },      
    },
    actions: {
	 	noFunc(){
				alert("该功能暂未开发，敬请期待！")
		},
        showMessage(context, options) {
          context.commit('showMessage', {...options})
        },
        showNotification(context, options) {
          context.commit('showNotification', {...options})
        },
      }
    
})

export default store