const video = {
    state: {
        videoRoom: 0,
        isBusy: false,
        people: 0
    },
    mutations: {
        GENERATE_VIDEO_ROOM(state, videoRoom) {
            if (videoRoom) {
                state.videoRoom = videoRoom
            } else {
                state.videoRoom = Math.ceil(Math.random() * (2 ** 32 - 1))
            }
        },
        UPDATE_ISBUSY(state, isBusy) {
            state.isBusy = isBusy
        },
        UPDATE_PEOPLE(state, people) {
            console.log('people'+people)
            state.people = people
        }
    },
    actions:{
        GENERATE_VIDEO_ROOM(context,videoRoom){
            context.commit('GENERATE_VIDEO_ROOM', videoRoom)
        },
        UPDATE_ISBUSY(context, isBusy) {
            context.commit('UPDATE_ISBUSY', isBusy)
        }
    }
}

export default video