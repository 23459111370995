import bus from "../../components/bus";

$(function () {
    let loaded = false;

    $('#commentTab').on('show.bs.tab', function () {
        if (!loaded) {
            bus.$emit('time-to-load-comments');
            loaded = true;
        }
    });
});
