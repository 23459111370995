var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    {
      staticClass: "navbar navbar-expand-md d-md-block d-none",
      class: _vm.navbarClass,
    },
    [
      _c("div", { class: _vm.containerStyle }, [
        _c(
          "a",
          {
            staticClass: "navbar-brand",
            attrs: { target: "_blank", href: _vm.link("home") },
          },
          [_c("i", { staticClass: "fas fa-home" }), _vm._v(" 91360首页")]
        ),
        _vm._v(" "),
        _c("ul", { staticClass: "mr-auto navbar-nav" }, [
          _c("li", { staticClass: "nav-item" }, [
            _c(
              "a",
              {
                staticClass: "nav-link",
                attrs: { href: _vm.link("meeting"), target: "_blank" },
              },
              [_vm._v("会议")]
            ),
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "nav-item" }, [
            _c(
              "a",
              {
                staticClass: "nav-link",
                attrs: { href: _vm.link("video"), target: "_blank" },
              },
              [_vm._v("直播")]
            ),
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "nav-item" }, [
            _c(
              "a",
              {
                staticClass: "nav-link",
                attrs: { href: _vm.link("qp"), target: "_blank" },
              },
              [_vm._v("切片库")]
            ),
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "nav-item" }, [
            _c(
              "a",
              {
                staticClass: "nav-link",
                attrs: { href: _vm.link("platform"), target: "_blank" },
              },
              [_vm._v("会诊平台")]
            ),
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "nav-item" }, [
            _c(
              "a",
              {
                staticClass: "nav-link",
                attrs: { href: _vm.link("comc"), target: "_blank" },
              },
              [_vm._v("玖壹叁陆零医学科技")]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "navbar-nav" },
          [
            !_vm.user
              ? [
                  _vm._m(0),
                  _vm._v(" "),
                  _c("li", { staticClass: "nav-item" }, [
                    _c(
                      "a",
                      {
                        staticClass: "nav-link px-2 nav-sep",
                        attrs: { href: _vm.link("register") },
                      },
                      [_vm._v("注册")]
                    ),
                  ]),
                ]
              : [
                  _c("li", { staticClass: "nav-item dropdown" }, [
                    _c(
                      "a",
                      {
                        staticClass:
                          "nav-link dropdown-toggle bmd-btn-icon dropdown-user",
                        attrs: {
                          href: "#",
                          role: "button",
                          "data-toggle": "dropdown",
                          "aria-haspopup": "true",
                          "aria-expanded": "false",
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "user-avatar rounded-circle mr-1",
                          attrs: { src: _vm.user.avatar, alt: "avatar" },
                        }),
                        _vm._v(
                          " " +
                            _vm._s(_vm.user.nickname) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "dropdown-menu dropdown-menu-mt dropdown-menu-right",
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "dropdown-item",
                            attrs: {
                              href: _vm.link("profile"),
                              target: "_blank",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "fas fa-address-card mr-1",
                            }),
                            _vm._v("个人中心"),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "dropdown-divider" }),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "dropdown-item",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.logout.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "fas fa-sign-out-alt mr-1",
                            }),
                            _vm._v("退出登录"),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ],
            _vm._v(" "),
            _vm._m(1),
            _vm._v(" "),
            _c("li", { staticClass: "nav-item dropdown" }, [
              _vm._m(2),
              _vm._v(" "),
              _c("div", { staticClass: "dropdown-menu dropdown-menu-right" }, [
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: _vm.links.app.zhbl, target: "_blank" },
                  },
                  [
                    _c("i", {
                      staticClass: "topbar-app-icon topbar-app-icon-blzj mr-1",
                    }),
                    _vm._v(" 智慧病理"),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: _vm.links.app.ktsc, target: "_blank" },
                  },
                  [
                    _c("i", {
                      staticClass: "topbar-app-icon topbar-app-icon-ktsc mr-1",
                    }),
                    _vm._v(" 抗体手册"),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: _vm.links.app.list, target: "_blank" },
                  },
                  [
                    _c("i", {
                      staticClass: "topbar-app-icon topbar-app-icon-more mr-1",
                    }),
                    _vm._v(" 更多应用"),
                  ]
                ),
              ]),
            ]),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal fade",
          attrs: {
            id: "dlgFeedback",
            tabindex: "-1",
            role: "dialog",
            "data-backdrop": "false",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "modal-dialog modal-sm",
              attrs: { role: "document" },
            },
            [
              _c("div", { staticClass: "modal-content" }, [
                _vm._m(3),
                _vm._v(" "),
                _c("div", { staticClass: "modal-body px-3 py-2" }, [
                  _c(
                    "form",
                    {
                      ref: "fbf",
                      attrs: { method: "post" },
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.feedback.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "form-group",
                          class: { "has-error": _vm.haserror("contact") },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.feedbackForm.phone,
                                expression: "feedbackForm.phone",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              placeholder: "您的联系方式",
                              required: "",
                            },
                            domProps: { value: _vm.feedbackForm.phone },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.feedbackForm,
                                  "phone",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("small", { staticClass: "help-block" }, [
                            _vm._v(
                              _vm._s(
                                _vm.haserror("contact")
                                  ? _vm.errors["contact"]
                                  : ""
                              )
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "form-group",
                          class: { "has-error": _vm.haserror("content") },
                        },
                        [
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.feedbackForm.content,
                                expression: "feedbackForm.content",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              required: "",
                              rows: "3",
                              placeholder: "感谢您对我们提出反馈意见",
                            },
                            domProps: { value: _vm.feedbackForm.content },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.feedbackForm,
                                  "content",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("small", { staticClass: "help-block" }, [
                            _vm._v(
                              _vm._s(
                                _vm.haserror("content")
                                  ? _vm.errors["content"]
                                  : ""
                              )
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        staticClass: "btn btn-primary btn-block",
                        attrs: { type: "submit", value: "提交" },
                      }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "modal-footer cc-modal-footer d-flex justify-content-between pb-2 px-3",
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "cc-contact-qq",
                        attrs: { href: _vm.link("feedbackqq") },
                      },
                      [
                        _c("span", { staticClass: "cc-icon cc-icon-qq" }),
                        _vm._v(" 联系QQ\n                        "),
                      ]
                    ),
                    _vm._v(" "),
                    _vm._m(4),
                  ]
                ),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal fade",
          attrs: {
            id: "dlgSignin",
            tabindex: "-1",
            role: "dialog",
            "data-backdrop": "static",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "modal-dialog modal-signin",
              attrs: { role: "document" },
            },
            [
              _c("div", { staticClass: "modal-content text-dark" }, [
                _vm._m(5),
                _vm._v(" "),
                _c("div", { staticClass: "modal-body" }, [
                  _vm._m(6),
                  _vm._v(" "),
                  _c("div", { staticClass: "tab-content form-wrapper mt-3" }, [
                    _c(
                      "div",
                      {
                        staticClass: "tab-pane fade show active",
                        attrs: { role: "tabpanel", id: "use-phone" },
                      },
                      [
                        _c(
                          "form",
                          {
                            attrs: { method: "post" },
                            on: {
                              submit: function ($event) {
                                $event.preventDefault()
                                return _vm.signinByPhone.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "form-group" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.credentialOfPhone.username,
                                    expression: "credentialOfPhone.username",
                                  },
                                ],
                                staticClass: "form-control",
                                class: { "is-invalid": _vm.haserror("phone") },
                                attrs: {
                                  type: "text",
                                  placeholder: "请输入手机号",
                                  required: "",
                                },
                                domProps: {
                                  value: _vm.credentialOfPhone.username,
                                },
                                on: {
                                  change: _vm.syncphone,
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.credentialOfPhone,
                                      "username",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("small", { staticClass: "invalid-feedback" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.haserror("phone")
                                      ? _vm.errors["phone"]
                                      : ""
                                  )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _vm.captchaInput.opened
                              ? _c(
                                  "div",
                                  { staticClass: "form-row form-captcha" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.pincodeForSignIn.captcha_key,
                                          expression:
                                            "pincodeForSignIn.captcha_key",
                                        },
                                      ],
                                      attrs: { type: "hidden" },
                                      domProps: {
                                        value: _vm.pincodeForSignIn.captcha_key,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.pincodeForSignIn,
                                            "captcha_key",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "form-group col-6" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.pincodeForSignIn
                                                  .captcha_value,
                                              expression:
                                                "pincodeForSignIn.captcha_value",
                                            },
                                          ],
                                          staticClass: "form-control w-100",
                                          class: {
                                            "is-invalid":
                                              _vm.haserror("captcha_value"),
                                          },
                                          attrs: {
                                            type: "text",
                                            placeholder: "请输入图形验证码",
                                          },
                                          domProps: {
                                            value:
                                              _vm.pincodeForSignIn
                                                .captcha_value,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                _vm.pincodeForSignIn,
                                                "captcha_value",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "small",
                                          { staticClass: "invalid-feedback" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.haserror("captcha_value")
                                                  ? _vm.errors["captcha_value"]
                                                  : ""
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "form-group col-4" },
                                      [
                                        _c("img", {
                                          staticClass:
                                            "form-control-captcha-image w-100",
                                          attrs: {
                                            src: _vm.captcha
                                              ? _vm.captcha.image
                                              : null,
                                            alt: "图形验证码",
                                            title: "点击更换验证码",
                                          },
                                          on: { click: _vm.getcaptcha },
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "form-group col-2 text-center",
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "form-control-captcha-image",
                                            attrs: { title: "点击更换验证码" },
                                            on: { click: _vm.getcaptcha },
                                          },
                                          [_vm._v("换一张")]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", { staticClass: "form-row" }, [
                              _c("div", { staticClass: "form-group col-7" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.credentialOfPhone.password,
                                      expression: "credentialOfPhone.password",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  class: {
                                    "is-invalid": _vm.haserror("pincode"),
                                  },
                                  attrs: {
                                    type: "text",
                                    placeholder: "请输入短信验证码",
                                    required: "",
                                  },
                                  domProps: {
                                    value: _vm.credentialOfPhone.password,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.credentialOfPhone,
                                        "password",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "small",
                                  { staticClass: "invalid-feedback" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.haserror("pincode")
                                          ? _vm.errors["pincode"]
                                          : ""
                                      )
                                    ),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "form-group col-5" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-primary btn-getcode btn-block",
                                    class: {
                                      disabled: _vm.cooldown ? true : false,
                                    },
                                    attrs: { type: "button" },
                                    on: { click: _vm.getpincode },
                                  },
                                  [_vm._v(_vm._s(_vm.cdText))]
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "form-group d-flex",
                                staticStyle: { "line-height": "30px" },
                              },
                              [
                                _c("div", { staticClass: "form-check" }, [
                                  _c(
                                    "label",
                                    { staticClass: "form-check-label" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.credentialOfPhone.remember,
                                            expression:
                                              "credentialOfPhone.remember",
                                          },
                                        ],
                                        staticClass: "form-check-input",
                                        attrs: { type: "checkbox" },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.credentialOfPhone.remember
                                          )
                                            ? _vm._i(
                                                _vm.credentialOfPhone.remember,
                                                null
                                              ) > -1
                                            : _vm.credentialOfPhone.remember,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a =
                                                _vm.credentialOfPhone.remember,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.credentialOfPhone,
                                                    "remember",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.credentialOfPhone,
                                                    "remember",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.credentialOfPhone,
                                                "remember",
                                                $$c
                                              )
                                            }
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _vm._m(7),
                                      _vm._v(
                                        "\n                                                下次自动登录\n                                            "
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "ml-auto" }, [
                                  _vm._v(
                                    "\n                                            登录即同意"
                                  ),
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: _vm.link("tos"),
                                        target: "_blank",
                                      },
                                    },
                                    [_vm._v("《服务及隐私条款》")]
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              staticClass: "btn btn-primary btn-block",
                              attrs: { type: "submit", value: "登录" },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "tab-pane fade",
                        attrs: { role: "tabpanel", id: "use-password" },
                      },
                      [
                        _c(
                          "form",
                          {
                            attrs: { method: "post" },
                            on: {
                              submit: function ($event) {
                                $event.preventDefault()
                                return _vm.signinByPassword.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "form-group",
                                class: {
                                  "has-error": _vm.haserror("username"),
                                },
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.credentialOfPassword.username,
                                      expression:
                                        "credentialOfPassword.username",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    placeholder: "手机号/用户名",
                                    required: "",
                                  },
                                  domProps: {
                                    value: _vm.credentialOfPassword.username,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.credentialOfPassword,
                                        "username",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("small", { staticClass: "help-block" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.haserror("username")
                                        ? _vm.errors["username"]
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "form-group",
                                class: {
                                  "has-error": _vm.haserror("password"),
                                },
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.credentialOfPassword.commonpassword,
                                      expression:
                                        "credentialOfPassword.commonpassword",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "password",
                                    placeholder: "密码",
                                    required: "",
                                  },
                                  domProps: {
                                    value:
                                      _vm.credentialOfPassword.commonpassword,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.credentialOfPassword,
                                        "commonpassword",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("small", { staticClass: "help-block" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.haserror("password")
                                        ? _vm.errors["password"]
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "form-group d-flex",
                                staticStyle: { "line-height": "30px" },
                              },
                              [
                                _c("div", { staticClass: "form-check" }, [
                                  _c(
                                    "label",
                                    { staticClass: "form-check-label" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.credentialOfPassword.remember,
                                            expression:
                                              "credentialOfPassword.remember",
                                          },
                                        ],
                                        staticClass: "form-check-input",
                                        attrs: { type: "checkbox" },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.credentialOfPassword.remember
                                          )
                                            ? _vm._i(
                                                _vm.credentialOfPassword
                                                  .remember,
                                                null
                                              ) > -1
                                            : _vm.credentialOfPassword.remember,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a =
                                                _vm.credentialOfPassword
                                                  .remember,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.credentialOfPassword,
                                                    "remember",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.credentialOfPassword,
                                                    "remember",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.credentialOfPassword,
                                                "remember",
                                                $$c
                                              )
                                            }
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _vm._m(8),
                                      _vm._v(
                                        "\n                                                下次自动登录\n                                            "
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "ml-auto" }, [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: _vm.link("forget"),
                                        target: "_blank",
                                      },
                                    },
                                    [_vm._v("忘记密码")]
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              staticClass: "btn btn-primary btn-block",
                              attrs: { type: "submit", value: "登录" },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "modal-footer justify-content-center pb-0" },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "mr-3",
                        attrs: { href: _vm.social("weixin") },
                      },
                      [
                        _c("i", {
                          staticClass: "cc-icon-2 icon-share icon-share-weixin",
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c("a", { attrs: { href: _vm.social("weibo") } }, [
                      _c("i", {
                        staticClass: "cc-icon-2 icon-share icon-share-weibo",
                      }),
                    ]),
                  ]
                ),
              ]),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "nav-item" }, [
      _c(
        "a",
        {
          staticClass: "nav-link px-2",
          attrs: { href: "#dlgSignin", "data-toggle": "modal", role: "button" },
        },
        [_vm._v("登录")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "nav-item" }, [
      _c(
        "a",
        {
          staticClass: "nav-link",
          attrs: { href: "#dlgFeedback", "data-toggle": "modal" },
        },
        [_vm._v("问题反馈")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "nav-link dropdown-toggle",
        attrs: {
          href: "#",
          "data-toggle": "dropdown",
          role: "button",
          "aria-haspopup": "true",
          "aria-expanded": "false",
        },
      },
      [_c("i", { staticClass: "fas fa-qrcode" }), _vm._v(" 应用")]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header px-3 pt-2" }, [
      _c("h5", { staticClass: "modal-title text-secondary" }, [
        _vm._v("问题反馈"),
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cc-contact-phone" }, [
      _c("span", { staticClass: "cc-icon cc-icon-phone" }),
      _vm._v(" 40004-91360\n                        "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c("img", {
        staticClass: "modal-title",
        attrs: {
          src: "http://img2.91360.com/img/logo.png",
          alt: "91360 logo",
          height: "36",
        },
      }),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "nav",
      {
        staticClass: "nav nav-pills nav-justified",
        attrs: { role: "tablist" },
      },
      [
        _c(
          "a",
          {
            staticClass: "nav-item nav-link active py-0 rounded-0 border-right",
            attrs: {
              href: "#use-phone",
              "aria-controls": "phone",
              role: "tab",
              "data-toggle": "pill",
            },
          },
          [_vm._v("快捷登录")]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "nav-item nav-link py-0 rounded-0",
            attrs: {
              href: "#use-password",
              "aria-controls": "password",
              role: "tab",
              "data-toggle": "pill",
            },
          },
          [_vm._v("密码登录")]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "form-check-sign" }, [
      _c("span", { staticClass: "check" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "form-check-sign" }, [
      _c("span", { staticClass: "check" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }