var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    _vm._l(_vm.lectures, function (lecture) {
      return _c(
        "div",
        { key: lecture.id, class: _vm.colClass },
        [
          _c("lecture-card", {
            attrs: { elClass: "card-less-padding", lecture: lecture },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }