var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ul", { class: _vm.elClass }, [
    _vm.userIsSignedIn
      ? _c(
          "li",
          { staticClass: "dropdown dropdown-no-caret d-none d-md-block" },
          [
            _c(
              "a",
              {
                staticClass: "nav-link dropdown-toggle p-0",
                attrs: {
                  href: "#userMenu",
                  "data-toggle": "dropdown",
                  role: "button",
                  "aria-haspopup": "true",
                  "aria-expanded": "false",
                },
              },
              [
                _c("img", {
                  staticClass: "user-avatar rounded-circle",
                  attrs: { src: _vm.user.picture, alt: "avatar" },
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "dropdown-menu dropdown-menu-right user-dropdown-menu",
              },
              [
                _c("h6", { staticClass: "dropdown-header" }, [
                  _vm._v(_vm._s(_vm.user.full_name)),
                ]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: _vm.user.dashboard, target: "_blank" },
                  },
                  [_vm._v("个人中心")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "dropdown-divider" }),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "dropdown-item",
                    attrs: { href: _vm.user.logout },
                  },
                  [_vm._v("退出登录")]
                ),
              ]
            ),
          ]
        )
      : _c("li", [
          _c(
            "a",
            {
              staticClass: "btn btn-primary btn-round d-none d-md-block",
              attrs: { href: "#userSign" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.launchDialog.apply(null, arguments)
                },
              },
            },
            [_vm._v("登录 / 注册")]
          ),
        ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal fade modal-topest",
        attrs: {
          id: "userSign",
          tabindex: "-1",
          role: "dialog",
          "data-backdrop": "false",
        },
      },
      [
        _c(
          "div",
          { staticClass: "modal-dialog modal-sm", attrs: { role: "document" } },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "modal-header" }, [
                _c("h5", { staticClass: "modal-title text-secondary mt-0" }, [
                  _vm._v(_vm._s(_vm.authenticateTitle)),
                ]),
                _vm._v(" "),
                _vm._m(0),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body" }, [
                _c(
                  "form",
                  {
                    staticClass: "user-sign-form",
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.loginOrRegister.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm.usingPassword
                      ? _c(
                          "div",
                          { staticClass: "form-group bmd-form-group d-block" },
                          [
                            _c("div", { staticClass: "input-group" }, [
                              _vm._m(1),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.auth.email,
                                    expression: "auth.email",
                                  },
                                ],
                                staticClass: "form-control",
                                class: _vm.hasError("email"),
                                attrs: {
                                  type: "text",
                                  placeholder: "手机 / 用户名",
                                  required: "",
                                },
                                domProps: { value: _vm.auth.email },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.auth,
                                      "email",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "small",
                                { staticClass: "invalid-feedback text-right" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.errorFeedback(
                                        "email",
                                        "请输入用户名或手机号码"
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        )
                      : _c(
                          "div",
                          { staticClass: "form-group bmd-form-group d-block" },
                          [
                            _c("div", { staticClass: "input-group" }, [
                              _vm._m(2),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.auth.mobile,
                                    expression: "auth.mobile",
                                  },
                                ],
                                staticClass: "form-control",
                                class: _vm.hasError("mobile"),
                                attrs: {
                                  id: "mobile",
                                  type: "text",
                                  placeholder: "手机",
                                  required: "",
                                },
                                domProps: { value: _vm.auth.mobile },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.auth,
                                      "mobile",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "small",
                                { staticClass: "invalid-feedback text-right" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.errorFeedback(
                                        "mobile",
                                        "请输入手机号码"
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                    _vm._v(" "),
                    _vm.usingPassword
                      ? _c(
                          "div",
                          { staticClass: "form-group bmd-form-group d-block" },
                          [
                            _c("div", { staticClass: "input-group" }, [
                              _vm._m(3),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.auth.password,
                                    expression: "auth.password",
                                  },
                                ],
                                staticClass: "form-control",
                                class: _vm.hasError("password"),
                                attrs: {
                                  type: "password",
                                  placeholder: "密码",
                                  required: "",
                                  autocomplete: "new-password",
                                },
                                domProps: { value: _vm.auth.password },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.auth,
                                      "password",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "small",
                                { staticClass: "invalid-feedback text-right" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.errorFeedback(
                                        "password",
                                        "请输入密码"
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        )
                      : _c(
                          "div",
                          {
                            staticClass:
                              "form-group bmd-form-group d-block position-relative",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "input-group" },
                              [
                                _vm._m(4),
                                _vm._v(" "),
                                _c("pin-button", {
                                  attrs: {
                                    "el-class": "pin-in-input",
                                    username: "mobile",
                                    cd: 60,
                                    usage: "auto",
                                    input: "#mobile",
                                  },
                                }),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.auth.pin,
                                      expression: "auth.pin",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  class: _vm.hasError("pin"),
                                  attrs: {
                                    id: "pin",
                                    type: "text",
                                    placeholder: "验证码1",
                                    required: "",
                                  },
                                  domProps: { value: _vm.auth.pin },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.auth,
                                        "pin",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "small",
                                  {
                                    staticClass: "invalid-feedback text-right",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.errorFeedback(
                                          "pin",
                                          "请输入手机验证码"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group d-flex" }, [
                      _c("div", { staticClass: "form-check ml-1" }, [
                        _c("label", { staticClass: "form-check-label" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.auth.remember,
                                expression: "auth.remember",
                              },
                            ],
                            staticClass: "form-check-input",
                            attrs: { type: "checkbox", id: "remember" },
                            domProps: {
                              checked: Array.isArray(_vm.auth.remember)
                                ? _vm._i(_vm.auth.remember, null) > -1
                                : _vm.auth.remember,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.auth.remember,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.auth,
                                        "remember",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.auth,
                                        "remember",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(_vm.auth, "remember", $$c)
                                }
                              },
                            },
                          }),
                          _vm._v(" "),
                          _vm._m(5),
                          _vm._v(
                            "\n                                    记住我\n                                "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "ml-auto" }, [
                        _c(
                          "a",
                          {
                            staticClass: "small",
                            attrs: { href: "#remember" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.switchAuthentication.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.switchText))]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary btn-block btn-round",
                        attrs: { type: "submit" },
                      },
                      [_vm._v(_vm._s(_vm.authenticateTitle))]
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.socialites
                  ? _c("div", [
                      _c("hr"),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "social-line text-center" },
                        _vm._l(_vm.socialites, function (social, id) {
                          return _c(
                            "a",
                            {
                              key: id,
                              staticClass: "btn btn-fab rounded-circle mx-1",
                              class: _vm.providerToClassName(
                                social.name,
                                "btn-"
                              ),
                              attrs: { href: social.url },
                            },
                            [
                              _c("i", {
                                staticClass: "fab fa-fw",
                                class: _vm.providerToClassName(
                                  social.name,
                                  "fa-"
                                ),
                              }),
                            ]
                          )
                        }),
                        0
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "modal",
          "aria-label": "Close",
        },
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c("span", { staticClass: "input-group-text" }, [
        _c("i", { staticClass: "material-icons" }, [_vm._v("face")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c("span", { staticClass: "input-group-text" }, [
        _c("i", { staticClass: "material-icons" }, [_vm._v("phone_android")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c("span", { staticClass: "input-group-text" }, [
        _c("i", { staticClass: "material-icons" }, [_vm._v("lock_outline")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c("span", { staticClass: "input-group-text" }, [
        _c("i", { staticClass: "material-icons" }, [_vm._v("fiber_pin")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "form-check-sign" }, [
      _c("span", { staticClass: "check" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }