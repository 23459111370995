var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      [
        _c("span", { staticClass: "label" }, [_vm._v("昵称:")]),
        _vm._v("\n    " + _vm._s(_vm.member.nick || "暂无") + "\n    "),
        _vm.showCancelBan
          ? _c(
              "a",
              {
                staticClass: "cursor-pointer",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.cancelMute.apply(null, arguments)
                  },
                },
              },
              [_vm._v("取消禁言")]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "el-popover",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showBan,
                expression: "showBan",
              },
            ],
            attrs: { title: "禁言" },
            model: {
              value: _vm.popoverVisible,
              callback: function ($$v) {
                _vm.popoverVisible = $$v
              },
              expression: "popoverVisible",
            },
          },
          [
            _c("el-input", {
              attrs: { placeholder: "请输入禁言时间" },
              nativeOn: {
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.setGroupMemberMuteTime.apply(null, arguments)
                },
              },
              model: {
                value: _vm.muteTime,
                callback: function ($$v) {
                  _vm.muteTime = $$v
                },
                expression: "muteTime",
              },
            }),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "cursor-pointer",
                staticStyle: { color: "red" },
                attrs: { slot: "reference" },
                slot: "reference",
              },
              [_vm._v("禁言")]
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _vm.showMuteUntil
      ? _c("div", [
          _c("span", { staticClass: "label" }, [_vm._v("禁言至:")]),
          _vm._v(" "),
          _c("span", { staticClass: "content" }, [
            _vm._v(_vm._s(_vm.muteUntil)),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.canChangeRole
      ? _c(
          "a",
          {
            staticClass: "text-primary cursor-pointer",
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.changeMemberRole.apply(null, arguments)
              },
            },
          },
          [
            _vm._v(
              "\n    " +
                _vm._s(
                  _vm.member.role === "Admin" ? "取消管理员" : "设为管理员"
                ) +
                "\n  "
            ),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.showKickout
      ? _c(
          "a",
          {
            staticClass: "cursor-pointer",
            staticStyle: { color: "red" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.kickoutGroupMember.apply(null, arguments)
              },
            },
          },
          [_vm._v("踢出群组")]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }