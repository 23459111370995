<template>
    <top-bar
        #default="sp"
        @messages="messages"
        @errors="errors"
        @logged-in="signedIn"
        @logged-out="signedOut"
        :endpoint="endpoint"
        :site="site"
    >
        <theme-bs4-material
            pos="sticky-top"
            :fluid="false"
            :user="sp.user"
            :links="sp.links"
            :cooldown="sp.cooldown"
            :captcha="sp.captcha"
            :closefeedback="sp.closefeedback"
        />
    </top-bar>
</template>

<script>
import ThemeBs4Material from "./ThemeBs4Material.vue";
import { toast, refreshCsrfToken } from '../../../helpers/utils';
import { UserService } from "../../services/UserService";

export default {
    data() {
        return {
            service: null,
            endpoint: '',
            site: ''
        };
    },
    mounted() {
        this.service = new UserService;
    },
    created() {
        this.site = this.$attrs.site+'/';
        this.endpoint = this.$attrs.endpoint;
    },
    methods: {
        messages(data) {
            data.detail && toast(data.detail);
        },
        errors(data) {
            toast(data.detail, 'snackbar-error');
        },
        async signedIn(user) {
            // let resp = await this.service.sso(user);
            console.log(user);
            // if (resp['next']) {
            //     let resp2 = await this.service.get(resp.next);
            //     refreshCsrfToken(resp2.csrf_token);
            if(user == undefined) return;
            if(user.pk != undefined){
                this.service.hub().$emit('user-logged-in');
            }
            if($('.p-app').length){
                this.$store.commit("SET_USER_TOKEN",user.sso_token);
                if(!user.is_identify){
                    //未认证提示认证框
                    this.$store.commit("SET_USER_INFO", true);
                }else{
                    //未登记提示登记
                    this.$store.commit("UPDATE_SHOWDIALOG", true);
                }
            }
            // }

            $('#dlgSignin').modal('hide');
        },
        signedOut() {
            // top bar control, wont use this anymore
            // this.service.signout();
        }
    },
    components: {
        ThemeBs4Material
    }
}
</script>
