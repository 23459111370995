<script>
import PinButton from "../auth/PinButton.vue";
import GdPinButton from "../auth/GdPinButton.vue";
import JxPinButton from "../auth/JxPinButton.vue";
import { UserService } from "../../services/UserService";
import { InputErrorHelper, refreshCsrfToken, toast } from '../../../helpers/utils';

export default {
    name: "UserMenu",

    props: {
        elClass: {
            type: [String, Object]
        },

        allowedSocialites: {
            type: [Array, Object],
            default: []
        }
    },

    data() {
        return {
            user: null,
            userService: null,
            socialites: this.allowedSocialites,
            errors: null,
            usingPassword: false,
            auth: {
                email: '',
                password: '',
                mobile: '',
                pin: '',
                remember: true
            }
        }
    },

    mounted() {
        this.userService = new UserService;

        // listen unauthenticated event, and pop signin dialog
        this.userService.hub().$on('unauthenticated', this.launchDialog);

        this.userService.hub().$on('update-user-menu', this.checkUser);

        this.checkUser();
    },

    methods: {
        launchDialog() {
            $('#userSign')
                .on('show.bs.modal', () => {
                    // if (null === this.socialites) {
                    //     this.getAvaliableSocialites();
                    // }
                })
                .modal('show');
        },

        closeDialog() {
            $('#userSign').modal('hide');
        },

        checkUser() {
            this.userService
                .check()
                .then(resp => this.user = resp.data);
        },

        getAvaliableSocialites() {
            this.userService
                .socialites()
                .then(data => this.socialites = data);
        },

        providerToClassName(name, prefix) {
            if (name == 'wechat') {
                name = 'weixin';
            }

            return prefix + name;
        },

        loginOrRegister() {
            this.errors = null;

            this.userService
                .loginOrRegister(this.auth, this.usingPassword)
                .then(resp => {
                    this.user = resp.data;

                    this.clearAuth()
                        .closeDialog();

                    refreshCsrfToken(resp.csrf_token);

                    // 推送事件
                    this.userService.hub().$emit('user-logged-in', {user: this.user});
                })
                .catch(err => {
                    this.errors = err.data.errors;

                    if (err.status == 419) {
                        toast('您的登录请求已过期，请刷新页面后重试', 'snackbar-error');
                    } else {
                        toast(err.data.message, 'snackbar-error');
                    }
                });
        },

        clearAuth() {
            this.auth.email = '';
            this.auth.password = '';
            this.auth.mobile = '';
            this.auth.pin = '';
            this.auth.remember = true;

            return this;
        },

        errorFeedback(key, defaults) {
            return InputErrorHelper.feedback(this.errors, key, defaults);
        },

        hasError(key) {
            return InputErrorHelper.check(this.errors, key) ? 'is-invalid' : '';
        },

        switchAuthentication() {
            this.usingPassword = !this.usingPassword;
        },
    },

    computed: {
        userIsSignedIn() {
            return this.user && this.user.id;
        },

        authenticateTitle() {
            return this.usingPassword ? '登录' : '登录 / 注册';
        },

        switchText() {
            return this.usingPassword ? '动态登录?' : '密码登录?';
        }
    },

    components: {
        'pin-button': PinButton,
        'gd-pin-button': GdPinButton,
        'Jx-pin-button': JxPinButton
    }
}
</script>

<template>
    <ul :class="elClass">
        <li v-if="userIsSignedIn" class="dropdown dropdown-no-caret d-none d-md-block">
            <a class="nav-link dropdown-toggle p-0" href="#userMenu" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                <img class="user-avatar rounded-circle" :src="user.picture" alt="avatar">
            </a>
            <div class="dropdown-menu dropdown-menu-right user-dropdown-menu">
                <h6 class="dropdown-header">{{ user.full_name }}</h6>
                <a class="dropdown-item" :href="user.dashboard" target="_blank">个人中心</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" :href="user.logout">退出登录</a>
            </div>
        </li>
        <li v-else><a href="#userSign" class="btn btn-primary btn-round d-none d-md-block" @click.prevent="launchDialog">登录 / 注册</a></li>

        <!--登录注册-模态框-->
        <div id="userSign" class="modal fade modal-topest" tabindex="-1" role="dialog" data-backdrop="false">
            <div class="modal-dialog modal-sm" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title text-secondary mt-0">{{ authenticateTitle }}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form @submit.prevent="loginOrRegister" class="user-sign-form">
                            <div v-if="usingPassword" class="form-group bmd-form-group d-block">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <i class="material-icons">face</i>
                                        </span>
                                    </div>
                                    <input type="text" class="form-control" :class="hasError('email')" placeholder="手机 / 用户名" required v-model="auth.email">
                                    <small class="invalid-feedback text-right">{{ errorFeedback('email', '请输入用户名或手机号码') }}</small>
                                </div>
                            </div>

                            <div v-else class="form-group bmd-form-group d-block">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <i class="material-icons">phone_android</i>
                                        </span>
                                    </div>
                                    <input id="mobile" type="text" class="form-control" :class="hasError('mobile')" placeholder="手机" required v-model="auth.mobile">
                                    <small class="invalid-feedback text-right">{{ errorFeedback('mobile', '请输入手机号码') }}</small>
                                </div>
                            </div>

                            <div v-if="usingPassword" class="form-group bmd-form-group d-block">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <i class="material-icons">lock_outline</i>
                                        </span>
                                    </div>
                                    <input type="password" class="form-control" :class="hasError('password')" placeholder="密码" required v-model="auth.password" autocomplete="new-password">
                                    <small class="invalid-feedback text-right">{{ errorFeedback('password', '请输入密码') }}</small>
                                </div>
                            </div>

                            <div v-else class="form-group bmd-form-group d-block position-relative">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <i class="material-icons">fiber_pin</i>
                                        </span>
                                    </div>

                                    <pin-button
                                        el-class="pin-in-input"
                                        username="mobile"
                                        :cd="60"
                                        usage="auto"
                                        input="#mobile"></pin-button>

                                    <input id="pin" type="text" class="form-control" :class="hasError('pin')" placeholder="验证码1" required v-model="auth.pin">
                                    <small class="invalid-feedback text-right">{{ errorFeedback('pin', '请输入手机验证码') }}</small>
                                </div>
                            </div>

                            <div class="form-group d-flex">
                                <div class="form-check ml-1">
                                    <label class="form-check-label">
                                        <input type="checkbox" class="form-check-input" id="remember" v-model="auth.remember">
                                        <span class="form-check-sign">
                                            <span class="check"></span>
                                        </span>
                                        记住我
                                    </label>
                                </div>

                                <div class="ml-auto">
                                    <a href="#remember" class="small" @click.prevent="switchAuthentication">{{ switchText }}</a>
                                </div>
                            </div>

                            <button type="submit" class="btn btn-primary btn-block btn-round">{{ authenticateTitle }}</button>
                        </form>

                        <div v-if="socialites">
                            <hr>
                            <div class="social-line text-center">
                                <a v-for="(social, id) in socialites" :key="id" :href="social.url" class="btn btn-fab rounded-circle mx-1" :class="providerToClassName(social.name, 'btn-')">
                                    <i class="fab fa-fw" :class="providerToClassName(social.name, 'fa-')"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ul>
</template>

<style lang="scss">
.pin-in-input {
    position: absolute;
    z-index: 10;
    right: 0;
    top: 0;

    + .form-control {
        padding-right: 5.1rem;
    }
}

.user-avatar {
    width: 2.5rem;
    height: 2.5rem;
}

.dropdown-no-caret {
    .dropdown-toggle::after {
        display: none;
    }
}

.user-dropdown-menu {
    min-width: 5rem;
}

.user-sign-form {
    .input-group-text {
        padding-left: 0;
    }
}
</style>
