import { render, staticRenderFns } from "./ThemeBs4Material.vue?vue&type=template&id=53bf58cf&scoped=true&"
import script from "./ThemeBs4Material.vue?vue&type=script&lang=js&"
export * from "./ThemeBs4Material.vue?vue&type=script&lang=js&"
import style0 from "./ThemeBs4Material.vue?vue&type=style&index=0&id=53bf58cf&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53bf58cf",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\UPUPW7.2\\htdocs\\v.91360.com\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('53bf58cf')) {
      api.createRecord('53bf58cf', component.options)
    } else {
      api.reload('53bf58cf', component.options)
    }
    module.hot.accept("./ThemeBs4Material.vue?vue&type=template&id=53bf58cf&scoped=true&", function () {
      api.rerender('53bf58cf', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/frontend/components/video/ThemeBs4Material.vue"
export default component.exports