import { render, staticRenderFns } from "./group-member-info.vue?vue&type=template&id=5a99663b&scoped=true&"
import script from "./group-member-info.vue?vue&type=script&lang=js&"
export * from "./group-member-info.vue?vue&type=script&lang=js&"
import style0 from "./group-member-info.vue?vue&type=style&index=0&id=5a99663b&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a99663b",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\UPUPW7.2\\htdocs\\v.91360.com\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5a99663b')) {
      api.createRecord('5a99663b', component.options)
    } else {
      api.reload('5a99663b', component.options)
    }
    module.hot.accept("./group-member-info.vue?vue&type=template&id=5a99663b&scoped=true&", function () {
      api.rerender('5a99663b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/frontend/im/components/conversation/conversationProfile/group-member-info.vue"
export default component.exports