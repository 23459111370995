<template>
	<nav class="navbar navbar-expand-md d-md-block d-none" :class="navbarClass">
		<div :class="containerStyle">
			<a class="navbar-brand" target="_blank" :href="link('home')"><i class="fas fa-home"></i> 91360首页</a>
            <ul class="mr-auto navbar-nav">
                <li class="nav-item"><a class="nav-link" :href="link('meeting')" target="_blank">会议</a></li>
                <li class="nav-item"><a class="nav-link" :href="link('video')" target="_blank">直播</a></li>
                <li class="nav-item"><a class="nav-link" :href="link('qp')" target="_blank">切片库</a></li>
                <li class="nav-item"><a class="nav-link" :href="link('platform')" target="_blank">会诊平台</a></li>
                <li class="nav-item"><a class="nav-link" :href="link('comc')" target="_blank">玖壹叁陆零医学科技</a></li>
            </ul>

            <ul class="navbar-nav">
                <template v-if="!user">
                    <li class="nav-item"><a href="#dlgSignin" class="nav-link px-2" data-toggle="modal" role="button">登录</a></li>
                    <li class="nav-item"><a class="nav-link px-2 nav-sep" :href="link('register')">注册</a></li>
                </template>
                <template v-else>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle bmd-btn-icon dropdown-user" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <img class="user-avatar rounded-circle mr-1" :src="user.avatar" alt="avatar"> {{ user.nickname }}
                        </a>
                        <div class="dropdown-menu dropdown-menu-mt dropdown-menu-right">
                            <a class="dropdown-item" :href="link('profile')" target="_blank"><i class="fas fa-address-card mr-1"></i>个人中心</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="#" @click.prevent="logout"><i class="fas fa-sign-out-alt mr-1"></i>退出登录</a>
                        </div>
                    </li>
                </template>
                <li class="nav-item"><a class="nav-link" href="#dlgFeedback" data-toggle="modal">问题反馈</a></li>
                <li class="nav-item dropdown">
                    <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i class="fas fa-qrcode"></i> 应用</a>
                    <div class="dropdown-menu dropdown-menu-right">
                        <a :href="links.app.zhbl" class="dropdown-item" target="_blank"><i class="topbar-app-icon topbar-app-icon-blzj mr-1"></i> 智慧病理</a>
                        <a :href="links.app.ktsc" class="dropdown-item" target="_blank"><i class="topbar-app-icon topbar-app-icon-ktsc mr-1"></i> 抗体手册</a>
                        <a :href="links.app.list" class="dropdown-item" target="_blank"><i class="topbar-app-icon topbar-app-icon-more mr-1"></i> 更多应用</a>
                    </div>
                </li>
            </ul>
		</div>
        <!-- Modal -->
        <div class="modal fade" id="dlgFeedback" tabindex="-1" role="dialog" data-backdrop="false">
            <div class="modal-dialog modal-sm" role="document">
                <div class="modal-content">
                    <div class="modal-header px-3 pt-2">
                        <h5 class="modal-title text-secondary">问题反馈</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div class="modal-body px-3 py-2">
                        <form ref="fbf" method="post" @submit.prevent="feedback">
                            <div class="form-group" :class="{'has-error':haserror('contact')}">
                                <input v-model="feedbackForm.phone" type="text" class="form-control" placeholder="您的联系方式" required>
                                <small class="help-block">{{ haserror('contact') ? errors['contact'] : '' }}</small>
                            </div>
                            <div class="form-group" :class="{'has-error':haserror('content')}">
                                <textarea v-model="feedbackForm.content" class="form-control" required rows="3" placeholder="感谢您对我们提出反馈意见"></textarea>
                                <small class="help-block">{{ haserror('content') ? errors['content'] : '' }}</small>
                            </div>
                            <input type="submit" class="btn btn-primary btn-block" value="提交">
                        </form>
                    </div>
                    <div class="modal-footer cc-modal-footer d-flex justify-content-between pb-2 px-3">
                        <a :href="link('feedbackqq')" class="cc-contact-qq">
                            <span class="cc-icon cc-icon-qq"></span> 联系QQ
                        </a>
                        <div class="cc-contact-phone">
                            <span class="cc-icon cc-icon-phone"></span> 40004-91360
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="dlgSignin" tabindex="-1" role="dialog" data-backdrop="static">
            <div class="modal-dialog modal-signin" role="document">
                <div class="modal-content text-dark">
                    <div class="modal-header">
                        <img class="modal-title" src="http://img2.91360.com/img/logo.png" alt="91360 logo" height="36">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div class="modal-body">
                        <nav class="nav nav-pills nav-justified" role="tablist">
                            <a href="#use-phone" class="nav-item nav-link active py-0 rounded-0 border-right" aria-controls="phone" role="tab" data-toggle="pill">快捷登录</a>
                            <a href="#use-password" class="nav-item nav-link py-0 rounded-0" aria-controls="password" role="tab" data-toggle="pill">密码登录</a>
                        </nav>
                        <div class="tab-content form-wrapper mt-3">
                            <div role="tabpanel" class="tab-pane fade show active" id="use-phone">
                                <form method="post" @submit.prevent="signinByPhone">
                                    <div class="form-group">
                                        <input v-model="credentialOfPhone.username" @change="syncphone" type="text" class="form-control" :class="{'is-invalid':haserror('phone')}" placeholder="请输入手机号" required>
                                        <small class="invalid-feedback">{{ haserror('phone') ? errors['phone'] : '' }}</small>
                                    </div>
                                    <div v-if="captchaInput.opened" class="form-row form-captcha">
                                        <input v-model="pincodeForSignIn.captcha_key" type="hidden">
                                        <div class="form-group col-6">
                                            <input v-model="pincodeForSignIn.captcha_value" type="text" class="form-control w-100" :class="{'is-invalid':haserror('captcha_value')}" placeholder="请输入图形验证码">
                                            <small class="invalid-feedback">{{ haserror('captcha_value') ? errors['captcha_value'] : '' }}</small>
                                        </div>
                                        <div class="form-group col-4">
                                            <img :src="captcha ? captcha.image : null" class="form-control-captcha-image w-100" alt="图形验证码" @click="getcaptcha" title="点击更换验证码">
                                        </div>
                                        <div class="form-group col-2 text-center">
                                            <span class="form-control-captcha-image" @click="getcaptcha" title="点击更换验证码">换一张</span>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="form-group col-7">
                                            <input v-model="credentialOfPhone.password" type="text" class="form-control" :class="{'is-invalid':haserror('pincode')}" placeholder="请输入短信验证码" required>
                                            <small class="invalid-feedback">{{ haserror('pincode') ? errors['pincode'] : '' }}</small>
                                        </div>
                                        <div class="form-group col-5">
                                            <button type="button" class="btn btn-primary btn-getcode btn-block" :class="{disabled:cooldown ? true : false}" @click="getpincode">{{ cdText }}</button>
                                        </div>
                                    </div>
                                    <div class="form-group d-flex" style="line-height:30px">
                                        <div class="form-check">
                                            <label class="form-check-label">
                                                <input type="checkbox" v-model="credentialOfPhone.remember" class="form-check-input">
                                                <span class="form-check-sign"><span class="check"></span></span>
                                                下次自动登录
                                            </label>
                                        </div>
                                        <div class="ml-auto">
                                            登录即同意<a :href="link('tos')" target="_blank">《服务及隐私条款》</a>
                                        </div>
                                    </div>
                                    <input type="submit" class="btn btn-primary btn-block" value="登录">
                                </form>
                            </div>
                            <div role="tabpanel" class="tab-pane fade" id="use-password">
                                <form method="post" @submit.prevent="signinByPassword">
                                    <div class="form-group" :class="{'has-error':haserror('username')}">
                                        <input v-model="credentialOfPassword.username" type="text" class="form-control" placeholder="手机号/用户名" required>
                                        <small class="help-block">{{ haserror('username') ? errors['username'] : '' }}</small>
                                    </div>
                                    <div class="form-group" :class="{'has-error':haserror('password')}">
                                        <input v-model="credentialOfPassword.commonpassword" type="password" class="form-control" placeholder="密码" required>
                                        <small class="help-block">{{ haserror('password') ? errors['password'] : '' }}</small>
                                    </div>
                                    <div class="form-group d-flex" style="line-height:30px">
                                        <div class="form-check">
                                            <label class="form-check-label">
                                                <input type="checkbox" class="form-check-input" v-model="credentialOfPassword.remember">
                                                <span class="form-check-sign"><span class="check"></span></span>
                                                下次自动登录
                                            </label>
                                        </div>
                                        <div class="ml-auto">
                                            <a :href="link('forget')" target="_blank">忘记密码</a>
                                        </div>
                                    </div>
                                    <input type="submit" class="btn btn-primary btn-block" value="登录">
                                </form>
                            </div>
                        </div>

                    </div>
                    <div class="modal-footer justify-content-center pb-0">
                        <a :href="social('weixin')" class="mr-3"><i class="cc-icon-2 icon-share icon-share-weixin"></i></a>
                        <a :href="social('weibo')"><i class="cc-icon-2 icon-share icon-share-weibo"></i></a>
                    </div>
                </div>
            </div>
        </div>
	</nav>
</template>

<script>
import { UserService } from "../../services/UserService";
import mixin from "top-bar/src/lib-components/mixin";
import { JSEncrypt } from 'encryptlong';

export default {
	mixins: [mixin],
    mounted() {
        let self = this;

        self.bindevents();

        $('#dlgSignin').on('show.bs.modal', function (e) {
            self.loadCaptcha();
        });
    },
	methods: {
        bindevents() {
            const service = new UserService;
            service.hub().$on('unauthenticated', () => {
                // $('.signin-dropdown-toggle').trigger('click');
                $("#dlgSignin").modal('show');
            });

            // stop propagation when switch tab in a  dropdown-menu
            // $(document).on('click', '.topbar-stop-propagation .dropdown-menu', function (e) {
            //     e.stopPropagation();
            // });
        },

        getpincode() {
            if (this.cooldown) {
                return false;
            }

            if (this.pincodeFormIsValid()) {
                this.getcode(this.pincodeForSignIn)
            }
        },

        loadCaptcha() {
            // 如果缩略图没加载
			if (!this.pincodeForSignIn.captcha_key) {
				this.captchaInput.opened = true;

				if (!this.captchaInput.loaded) {
					this.getcaptcha();
					this.captchaInput.loaded = true;
				}
			}
        },

        pincodeFormIsValid() {
            this.loadCaptcha();

            if (!this.pincodeForSignIn.phone || !/^1\d{10}$/.test(this.pincodeForSignIn.phone)) {
                this.seterror('phone', 'required');
            } else {
                this.clearerror('phone');
            }

            if (!this.pincodeForSignIn.captcha_value) {
                this.seterror('captcha_value', 'required');
            } else {
                this.clearerror('captcha_value');
            }

            if (this.haserror('phone') || this.haserror('captcha_value')) {
                return false;
            }

            return true;
        },

        signinByPhone() {
            if (!this.credentialOfPhone.username || !/^1\d{10}$/.test(this.credentialOfPhone.username)) {
                this.seterror('phone', 'required');
            } else {
                this.clearerror('phone');
            }

            if (!this.credentialOfPhone.password) {
                this.seterror('pincode', 'required');
            } else {
                this.clearerror('pincode');
            }

            if (this.haserror('phone') || this.haserror('pincode')) {
                return false;
            }
            this.login(this.credentialOfPhone);
        },
        setEncryptedData(password) {
            var encryptor = new JSEncrypt();  // 创建加密对象实例
            var pubKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQChTKoWHCjOcjMbie6lVh5pU5MPxbdRvA1qVlvCef4XSepuS/+jH6IslFKWf37onRU5ApjT668wKikZts4hL8TwKq9jsWJA+3rVngXyw+Bf4uVYKYA2aWMc25OINACY3ooBIr/KgPJgyt8bZtzJr8QTXdb0WMZfVbv387AQmvwvfwIDAQAB';
            encryptor.setPublicKey(pubKey);
            return encryptor.encryptLong(password);
        },
        signinByPassword() {
            if (!this.credentialOfPassword.username) {
                this.seterror('username', 'required');
            } else {
                this.clearerror('username');
            }

            if (!this.credentialOfPassword.commonpassword) {
                this.seterror('password', 'required');
            } else {
                this.clearerror('password');
            }

            if (this.haserror('username') || this.haserror('password')) {
                return false;
            }
            this.credentialOfPassword.password = this.setEncryptedData(this.credentialOfPassword.commonpassword)
            // this.credentialOfPassword.password = this.setEncryptedData(this.credentialOfPassword.password);

            var login_data = {"username":this.credentialOfPassword.username,"password":this.credentialOfPassword.password,"remember":this.credentialOfPassword.remember,"use_pin_code":this.credentialOfPassword.use_pin_code}
            this.login(login_data);
            // service.hub().$on("user-logged-in", (e) => {this.$refs.liveLogin.initUser();});
        },

        feedback() {
            if (!this.feedbackForm.phone) {
                this.seterror('contact', 'required');
            } else {
                this.clearerror('contact');
            }

            if (!this.feedbackForm.content) {
                this.seterror('content', 'required');
            } else {
                this.clearerror('content');
            }

            if (this.haserror('contact') || this.haserror('content')) {
                return false;
            }

            this.sendfeedback(this.feedbackForm);
        },

        seterror(field, validator) {
            this.errors[field] = this.messages[field][validator];
        },

        clearerror(field) {
            delete this.errors[field];
        },

        haserror(field) {
            return this.errors.hasOwnProperty(field);
        }
    },
	computed: {
		navbarClass() {
			return [
				`navbar-${this.color} bg-${this.color}`,
				this.pos
			];
		},
		containerStyle() {
			return this.fluid ? "container-fluid" : "container";
        }
    },
    watch: {
        closefeedback(newval, old) {
            if (newval) {
                this.$refs.fbf.reset();
                $('#dlgFeedback').modal('hide');
            }
        }
    }
};
</script>

<style scoped>
.navbar {
    min-height: 40px;
    line-height: 40px;
    margin-bottom: 0;
    border-width: 0;
    z-index: 1100;
    padding: 0;
    border-radius: 0;
}
.navbar.bg-dark {
    box-shadow: none;
}
.navbar-brand {
    padding-top: 5px;
    padding-bottom: 5px;
    line-height: 30px;
    height: unset;
    font-size: 13px;
}
.modal-signin {
    width: 442px;
}
.modal-signin .modal-header,
.modal-signin .modal-footer,
.modal-signin .modal-body {
    padding-right: 40px;
    padding-left: 40px;
}
.modal-signin .modal-body {
    padding-bottom: 30px;
}
.modal-signin .modal-footer {
    background-color: #f0fafc;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}
.dropdown-menu {
    min-width: 90px;
}
.dropdown-menu-mt {
    margin-top: 0.125rem !important;
}
.dropdown-menu>li>a,
.dropdown-menu .dropdown-item,
.dropdown-menu .dropdown-header {
    padding-right: 10px;
    padding-left: 10px;
}
.dropdown-menu .dropdown-header {
    margin: 0 0.3125rem;
}
.navbar .navbar-nav .nav-item .nav-link {
    padding: 10px;
    font-size: 13px;
}
.navbar .dropdown-signin-menu.form-wrapper {
    width: 260px;
    padding: 10px 15px;
    font-size: 12px;
    border-radius: 5px;
    margin-top: 0.125rem;
}
.navbar .form-wrapper a {
    color: #4a95fb;
}
.nav-sep::before {
    position: absolute;
    content: '';
    width: 0;
    left: 0;
    top: 15px;
    bottom: 15px;
    border-left: 1px solid #fff;
}
.nav-pills {
    padding: 0;
}
.nav-pills:not(.flex-column) .nav-item + .nav-item:not(:first-child) {
    margin-left: 0;
}
.nav-pills .nav-link {
    margin: 0;
    color: #333 !important;
    font-size: 18px !important;
    text-align: center;
}
.nav-pills .nav-link:hover,
.nav-pills .nav-link.active {
    background-color: transparent;
    box-shadow: none;
}
.nav-pills .nav-link.active {
    color: #4a95fb !important;
    font-weight: bold;
}
.dropdown-menu a:hover,
.dropdown-menu a:focus,
.dropdown-menu a:active {
    box-shadow: none;
    background-color: transparent;
    color: #1273fa;
}
.navbar .dropdown-menu .form-group,
.modal-dialog .form-group {
    display: block;
    margin-bottom: 20px;
}
.navbar .dropdown-menu .form-group {
    margin-bottom: 10px;
}
.navbar .form-group .invalid-feedback {
    line-height: 1.4;
}
.form-control-captcha-image {
    cursor: pointer;
    height: 36px;
    border-radius: 4px;
    vertical-align: top;
}
.btn-getcode {
    padding: 9px;
}
.btn-primary {
    background-color: #4a95fb;
    border-color: #4a95fb;
}
.btn-primary:hover {
    background-color: #6a9fe8;
    border-color: #6a9fe8;
}
.form-check {
    font-size: 80%;
    margin-bottom: 0;
    line-height: 30px;
}
.form-check .form-check-label {
    padding-left: 20px;
    font-size: 70%;
}
.form-check .form-check-label span {
    top: -1px;
    left: 0;
}
.form-check .form-check-sign {
    padding-right: 0;
}
.form-check .form-check-sign:before {
    border-radius: 0;
    transform: scale3d(1, 1, 1);
    -webkit-transform: scale3d(1, 1, 1);
    height: 15px;
    width: 15px;
    top: -1px;
}
.form-check .form-check-sign .check {
    width: 15px;
    height: 15px;
}
.form-check .form-check-sign .check:before {
    margin-top: -5px;
    margin-left: 5px;
}
.navbar .navbar-nav .nav-item .nav-link.dropdown-user {
    padding-top: 5px;
    padding-bottom: 5px;
    line-height: 30px;
}
.user-avatar {
    width: 30px;
    height: 30px;
    vertical-align: top;
}
.navbar .form-wrapper .form-footer {
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 12px;
    line-height: 30px;
}
.navbar .form-wrapper .form-footer a:hover{
    text-decoration: underline;
}
.cc-icon-2 {
    display: inline-block;
    vertical-align: middle;
    background-image: url(//img2.91360.com/img/icon_s.png);
    background-repeat: no-repeat;
    background-size: 72px auto;
}
.icon-share {
    width: 24px;
    height: 24px;
    cursor: pointer;
}
.icon-share-qq {
    background-position: 0 0;
}
.icon-share-weixin {
    background-position: -24px 0;
}
.icon-share-weibo {
    background-position: -48px 0;
}
.icon-share:hover {
    opacity: 0.7;
}
.modal-header .close {
    font-size: 2rem;
    transition: all 0.4s ease-out;
}
.modal-header .close:hover {
    transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -webkit-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
}
@media (max-width: 768px){
    .navbar .dropdown-signin-menu.form-wrapper {
        width: 100%;
        padding: 10px 15px;
        background: transparent;
        border: none;
    }
    .navbar .nav .btn-primary,
    .navbar .nav .btn-primary:active {
        display: block;
    }
}
</style>
