
export function throttle(func, wait) {
    let timeout
    return function () {
        let that = this
        let args = arguments

        if (!timeout) {
            timeout = setTimeout(() => {
                timeout = null
                func.apply(that, args)
            }, wait)
        }
    }
}

/**
 * 利用 document.title 做新消息提示
 * @export
 * @param {Number} count
 */
export function titleNotify(count) {
    const hasNewMessage = count > 0
    if (hasNewMessage) {
        if (document.title.search(/\((.*?)\)/) >= 0) {
            document.title = document.title.replace(/\((.*?)\)/, `(${count > 99 ? '99+' : count})`)
        } else {
            document.title = `(${count})${document.title}`
        }
    } else {
        document.title = document.title.replace(/\((.*?)\)/, '')
    }
}

export function suffix(name, suf) {
    if (!suf) {
        return name;
    }

    let found = name.match(/^([^\s]+)\s+([^\s]+)$/);

    if (found) {
        return found[1] + suf + ' ' + found[2];
    }

    return name;
}
