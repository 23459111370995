<script>
export default {
    name: "CommentItem",

    props: {
        comment: {
            type: Object,
            required: true
        },

        replied: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {

        }
    },

    methods: {
        hasReplies(comment) {
            return comment.replies && comment.replies.length > 0;
        },

        popReply(comment) {
            this.$emit('reply-button-clicked', comment);
        }
    }
}
</script>
<style>
    .badge{padding:3px 5px;}
</style>

<template>
    <div class="media" :class="{'mb-4':replied}">
        <template v-if="comment.commenter">
            <div class="mr-3">
                <div class="avatar">
                    <img class="media-object" :src="comment.commenter.picture" :alt="comment.commenter.full_name + ' avatar'">
                </div>

            </div>
            <div class="media-body">
                <h6 class="media-heading mt-0">{{ comment.commenter.room_name }}
                    <span class="badge badge-success"></span>
                    <small class="text-muted">· {{ comment.created_at }}</small>
                </h6>
                <p class="text-muted mb-0">{{ comment.message }}</p>
                <div class="media-footer text-right">
                    <a v-if="!replied" href="#" class="btn btn-sm btn-primary btn-link" @click.prevent="popReply(comment)" rel="tooltip" title="" data-original-title="回复"><i class="material-icons">reply</i> 回复</a>

                    <a v-if="comment.video" :href="comment.video.url" class="btn btn-sm btn-link" target="_blank"><i class="material-icons">link</i> {{ comment.video.name }}</a>
                </div>

                <template v-if="hasReplies(comment)">
                    <comment-item
                        v-for="reply in comment.replies"
                        :key="reply.id"
                        :comment="reply"
                        :replied="true"
                    ></comment-item>
                </template>
            </div>
        </template>
        <div v-else class="alert alert-secondary" role="alert">该留言已删除!</div>
    </div>
</template>
