var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card", class: _vm.elClass }, [
    _c(
      "div",
      { staticClass: "card-header card-header-primary card-header-expert" },
      [
        _c("ul", { staticClass: "nav nav-tabs" }, [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showChatTab,
                  expression: "showChatTab",
                },
              ],
              staticClass: "nav-item",
            },
            [_vm._m(1)]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showChatTab,
                  expression: "showChatTab",
                },
              ],
              staticClass: "nav-item",
            },
            [_vm._m(2)]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showChatTab,
                  expression: "showChatTab",
                },
              ],
              staticClass: "nav-item",
            },
            [
              _c(
                "a",
                {
                  staticClass: "nav-link",
                  style: { display: _vm.checkMessage == 1 ? "table" : "none" },
                  attrs: {
                    id: "userTab",
                    "data-toggle": "tab",
                    href: "#tab-examine",
                  },
                },
                [
                  _c("i", { staticClass: "far fa-eye" }),
                  _vm._v(" 审核\n      "),
                ]
              ),
            ]
          ),
        ]),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "card-body p-0" }, [
      _c(
        "div",
        { staticClass: "tab-content" },
        [
          _c(
            "div",
            { staticClass: "tab-pane active", attrs: { id: "tab-expert" } },
            [_vm._t("default")],
            2
          ),
          _vm._v(" "),
          [
            _c(
              "div",
              { staticClass: "tab-pane", attrs: { id: "tab-interact" } },
              [
                _c("chat-room", {
                  on: {
                    "show-chat-tab": _vm.openChat,
                    "close-chat-tab": _vm.closeChat,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "tab-pane", attrs: { id: "tab-userlist" } },
              [
                _vm.currentConversation.type === _vm.TIM.TYPES.CONV_GROUP
                  ? _c("group-profile", {
                      attrs: {
                        groupProfile: _vm.currentConversation.groupProfile,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "tab-pane", attrs: { id: "tab-examine" } },
              [_c("chat-examine")],
              1
            ),
          ],
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "nav-item" }, [
      _c(
        "a",
        {
          staticClass: "nav-link active",
          attrs: { id: "expertTab", "data-toggle": "tab", href: "#tab-expert" },
        },
        [_c("i", { staticClass: "fas fa-user-md" }), _vm._v(" 专家\n        ")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "nav-link",
        attrs: { id: "chatTab", "data-toggle": "tab", href: "#tab-interact" },
      },
      [_c("i", { staticClass: "far fa-comments" }), _vm._v(" 互动\n      ")]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "nav-link",
        attrs: { id: "userTab", "data-toggle": "tab", href: "#tab-userlist" },
      },
      [_c("i", { staticClass: "far fa-eye" }), _vm._v(" 在线用户\n      ")]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }