var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.number
    ? _c("span", [_vm._v("\n    " + _vm._s(_vm.number) + " /\n")])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }