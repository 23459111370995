<template>
  <div class="group-member-list-wrapper">
    <div class="header">
      <span class="member-count text-ellipsis">
        成员：
        <online-number v-if="canViewOnlines" />
        {{currentConversation.groupProfile.memberNum}}
      </span>
    </div>
    <div class="scroll-content">
      <div class="group-member-list">
        <div class="list">
          <template v-for="member in members">
            <popover placement="right" :key="member.userID">
              <group-member-info :member="member" />
              <div slot="reference" class="group-member" @click="currentMemberID = member.userID">
                <!-- <avatar :title=getGroupMemberAvatarText(member.role) :src="member.avatar" /> -->
                <div
                  :title="getGroupMemberAvatarText(member.role)"
                  class="member-name text-ellipsis"
                >
                  <span v-if="member.nick" :title="member.nick">{{ blk(member.nick) }}</span>
                  <span v-else :title="member.userID">{{ member.userID }}</span>
                </div>
              </div>
            </popover>
          </template>
        </div>
      </div>
    </div>
    <div class="more">
      <a v-if="showLoadMore" @click.prevent="loadMore">查看更多</a>
    </div>
  </div>
</template>

<script>
import { Popover } from "element-ui";
import { mapState } from "vuex";
import GroupMemberInfo from "./group-member-info.vue";
import OnlineNumber from "./group-online-number.vue";
import { suffix } from "../../../utils/index";

export default {
  data() {
    return {
      addGroupMemberVisible: false,
      currentMemberID: "",
      count: 100 // 显示的群成员数量
    };
  },
  props: ["groupProfile"],
  components: {
    Popover,
    GroupMemberInfo,
    OnlineNumber
  },
  computed: {
    ...mapState({
      currentConversation: state => state.conversation.currentConversation,
      currentMemberList: state => state.group.currentMemberList,
      currentSiteUser: state => state.user.currentSiteUser,
      currentRoom: state => state.group.currentRoom
    }),
    showLoadMore() {
      return this.members.length < this.groupProfile.memberNum;
    },
    members() {
      return this.currentMemberList.slice(0, this.count);
    },
    canViewOnlines() {
        return ['Owner', 'Admin'].includes(this.groupProfile.selfInfo.role);
    //   return this.currentSiteUser.viewOnlines;
    }
  },
  methods: {
    blk(name) {
      return this.canViewOnlines ? suffix(name, this.currentRoom.suffix) : name;
    },
    getGroupMemberAvatarText(role) {
      switch (role) {
        case "Owner":
          return "群主";
        case "Admin":
          return "管理员";
        default:
          return "群成员";
      }
    },
    loadMore() {
      this.$store
        .dispatch("getGroupMemberList", this.groupProfile.groupID)
        .then(() => {
          this.count += 100;
        });
    }
  }
};
</script>
