<script>
import { ApplyService } from "../../services/ApplyService";
import { toast, InputErrorHelper } from '../../../helpers/utils';
import CityPicker from "../../../components/citypicker/CityPicker.vue";

export default {
    name: 'AskFor',

    props: {
        elClass: {
            type: [String, Object]
        }
    },

    data() {
        return {
            service: null,
            errors: null,
            info: {
                content: {
                    real_name: '',
                    title: '',
                    state: null,
                    city: null,
                    contact: '',
                    band_width: '',
                    address : ''
                },

                type: null
            }
        }
    },

    mounted() {
        this.service = new ApplyService;
    },

    methods: {
        showForm(type) {
            this.info.type = type;

            $('#applyForm').modal('show');
        },

        ask() {
            this.errors = null;

            this.service.ask(this.info)
                .then(resp => {
                    toast(resp.message);

                    $('#applyForm').modal('hide');
                })
                .catch(err => this.errors = err.data.errors);
        },

        choiceCallers(values) {
            let id = values[1];

            if (/^\d{2}0{4}$/.test(id)) {  // so it is provice, and when chose state, the city will be empty
                this.info.content.state = id;
                this.info.content.city = null;
            } else {
                this.info.content.city = id;
            }
        },

        errorFeedback(key, defaults) {
            return InputErrorHelper.feedback(this.errors, key, defaults);
        },

        hasError(key) {
            return InputErrorHelper.check(this.errors, key) ? 'is-invalid' : '';
        },
    },

    computed: {
        headerTitle() {
            return '我要' + (this.info.type ==1 ? '直播' : '讲课');
        }
    },

    components: {
        'city-picker': CityPicker
    }
}
</script>

<template>
    <div :class="elClass">
        <a href="#" class="btn btn-outline-warning btn-round" @click.prevent="showForm(1)">
            <i class="material-icons">video_call</i> 申请直播
        </a>
        <a href="#" class="btn btn-outline-primary btn-round" @click.prevent="showForm(2)">
            <i class="material-icons">voice_chat</i> 我要讲课
        </a>

        <div class="modal fade" id="applyForm" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title mt-0">{{ headerTitle }}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body py-0">
                        <div class="form-group">
                            <input type="text" required placeholder="讲师姓名" class="form-control" :class="hasError('content.real_name')" v-model="info.content.real_name">
                            <small class="text-right invalid-feedback">{{ errorFeedback('content.real_name', '请输入讲师姓名') }}</small>
                        </div>
                        <div class="form-group">
                            <input type="text" required placeholder="课题" class="form-control" :class="hasError('content.title')" v-model="info.content.title">
                            <small class="text-right invalid-feedback">{{ errorFeedback('content.title', '请输入题目') }}</small>
                        </div>
                        <div class="form-group">
                            <city-picker
                                el-class="form-row"
                                item-class="col"
                                :china="true"
                                :level="2"
                                :selectpattern="[{
                                    field: 'state',
                                    placeholder: '请选择省份'
                                }, {
                                    field: 'city',
                                    placeholder: '请选择城市'
                                }]"
                                @choice-caller="choiceCallers"
                            ></city-picker>
                        </div>
                        <div class="form-group">
                            <input type="text" required placeholder="详细地址" class="form-control" :class="hasError('content.address')" v-model="info.content.address">
                            <small class="text-right invalid-feedback">{{ errorFeedback('content.address', '请输入详细地址') }}</small>
                        </div>
                        <div class="form-group">
                            <input type="text" required placeholder="手机号码" class="form-control" :class="hasError('content.contact')" v-model="info.content.contact">
                            <small class="text-right invalid-feedback">{{ errorFeedback('content.contact', '请输入手机号码') }}</small>
                        </div>
                        <div class="form-group">
                            <input type="text" required placeholder="宽带" class="form-control" :class="hasError('content.band_width')" v-model="info.content.band_width">
                            <small class="text-right invalid-feedback">{{ errorFeedback('content.band_width', '请输入宽带') }}</small>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" @click="ask">提交申请</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.city-picker {
    text-align: left;
}
</style>
