<script>
import { CommentService } from "../../services/CommentService";
import { InputErrorHelper, toast } from '../../../helpers/utils';
import CommentItem from './CommentItem.vue';
import Pagination from 'laravel-vue-pagination';

export default {
    name: "Comment",

    props: {
        elClass: {
            type: [String, Object]
        },

        loadFrom: {
            type: String,
            required: true
        },

        limitaion: {
            type: Number,
            default: 150
        },

        loadDirect: {
            type: Boolean,
            default: false
        },

        commentableType: {
            type: String,
            required: true
        },

        commentableId: {
            type: Number,
            required: true
        }
    },

    data() {
        return {
            comments: null,

            commentService: null,

            create: {
                commentable_type: this.commentableType,
                commentable_id: this.commentableId,
                message: null
            },

            reply: {
                message: null
            },

            errors: null,

            createLimit: this.limitaion,

            replyLimit: this.limitaion,

            replyTo: null
        }
    },

    mounted() {
        this.commentService = new CommentService;

        // 如果直接加载
        if (this.loadDirect) {
            this.clearAndLoad();
        } else { // 否则监听才加载
            this.commentService.hub().$on('time-to-load-comments', this.loadComments);
        }
    },

    methods: {
        loadComments(page = 1) {
            this.commentService.pagination(this.loadFrom, page)
                .then(resp => {
                    this.comments = resp;
                });
        },

        clearAndLoad() {
            this.comments = null;
            this.loadComments();
        },

        errorFeedback(key, defaults) {
            return InputErrorHelper.feedback(this.errors, key, defaults);
        },

        hasError(key) {
            return InputErrorHelper.check(this.errors, key) ? 'is-invalid' : '';
        },

        postComment() {
            this.commentService.create(this.create)
                .then(resp => {
                    toast(resp.message);

                    this.resetCreateData()
                        .clearAndLoad();
                });
        },

        replyComment() {
            this.commentService.reply(this.replyTo, this.reply)
                .then(resp => {
                    toast(resp.message);

                    $('#replyComment').modal('hide');

                    this.resetReplyData()
                        .clearAndLoad();
                });
        },

        limitInput(how, event) {
            let target = event.target,
                limit = this.$data[how + 'Limit'];

            if (target.value.length > this.limitaion) {
                target.value = target.value.substring(0, this.limitaion);
            }

            this.$set(this.$data, how + 'Limit', this.limitaion - target.value.length);
        },

        limitaionText(how) {
            return '还可以输入<font class="text-warning text-count">' + this.$data[how + 'Limit'] + '</font>个字';
        },

        resetCreateData() {
            this.create.message = null;
            this.createLimit = this.limitaion;

            return this;
        },

        resetReplyData() {
            this.reply.message = null;
            this.replyLimit = this.limitaion;
            this.replyTo = null;

            return this;
        },

        popReplyDialog(comment) {
            this.replyTo = comment;

            $('#replyComment').modal('show');
        }
    },

    computed: {
        hasComments() {
            return this.comments && _.isArray(this.comments.data) && this.comments.data.length > 0;
        }
    },

    components: {
        'comment-item': CommentItem,
        'pagination': Pagination
    }
}
</script>

<template>
    <div :class="elClass">
        <div class="comment-form-box">
            <div class="comment-media clearfix">
                <div class="comment-media-body">
                    <form @submit.prevent="postComment">
                        <div class="d-alert-box"></div>
                        <div class="at-box"></div>
                        <div class="form-group position-relative mb-0">
                            <textarea
                                class="form-control"
                                :class="hasError('message')"
                                rows="3"
                                placeholder="输入内容，参与留言。"
                                required
                                v-model="create.message"
                                @load="limitInput('create', $event)"
                                @keyup="limitInput('create', $event)"
                                @paste="limitInput('create', $event)"
                            ></textarea>
                            <small class="invalid-feedback">{{ errorFeedback('message') }}</small>
                            <small class="form-text text-secondary d-count" v-html="limitaionText('create')"></small>
                        </div>
                        <div class="text-right">
                            <button type="submit" class="btn btn-warning btn-round">发表留言</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <br />

        <div class="d-comment-list">
            <div v-if="hasComments" class="comment-lists list-unstyled">
                <comment-item
                    v-for="comment in comments.data"
                    :key="comment.id"
                    :comment="comment"
                    @reply-button-clicked="popReplyDialog"
                ></comment-item>
            </div>
            <p v-else class="lead">目前尚无留言, 来个沙发?</p>

            <div v-if="hasComments" class="d-flex mt-4 justify-content-end">
                <pagination :data="comments" @pagination-change-page="loadComments"></pagination>
            </div>
        </div>

        <div class="modal fade" id="replyComment" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <form class="comment-reply-form" @submit.prevent="replyComment">
                        <div class="modal-header">
                            <h5 class="modal-title mt-0">回复留言</h5>
                            <button type="button" class="close" data-dismiss="modal"><span>&times;</span></button>
                        </div>
                        <div class="modal-body">
                            <div class="form-group">
                                <textarea
                                    class="form-control"
                                    :class="hasError('message')"
                                    required
                                    rows="3"
                                    v-model="reply.message"
                                    @load="limitInput('reply', $event)"
                                    @keyup="limitInput('reply', $event)"
                                    @paste="limitInput('reply', $event)"
                                    placeholder="您的回复"
                                ></textarea>
                                <small class="invalid-feedback">{{ errorFeedback('message') }}</small>
                                <small class="form-text text-secondary d-count" v-html="limitaionText('reply')"></small>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-sm btn-rose btn-round">回复</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
