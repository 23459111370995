<template>
    <a
        href="#like"
        @click.prevent="toggle"
        :class="elClass"
        :title="liked ? defaultText.liked : defaultText.like"
    >
        <i
            v-if="fillable.icon && fillable.icon.length > 0"
            :class="[fillable.icon, { active: liked }]"
        ></i>
        {{ text }}
    </a>
</template>

<script>
import { LikeService } from "../../services/LikeService";
import { toast } from "../../../helpers/utils";

export default {
    name: "Like",

    props: {
        elClass: {
            type: [Object, String]
        },

        checkFrom: {
            type: String,
            required: true
        },

        toggleApi: {
            type: String,
            required: false
        },

        likeApi: {
            type: String,
            required: true
        },

        token: {
            type: String,
            required: true
        },

        model: {
            type: Object,
            required: true
        },

        hasLiked: {
            type: Boolean,
            default: false
        },

        initCheck: {
            type: Boolean,
            default: false
        },

        fill: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },

    data() {
        return {
            likeService: null,
            defaultText: {
                icon: "heart",
                liked: "已收藏",
                like: "收藏"
            },
            liked: this.hasLiked
        };
    },

    mounted() {
        this.likeService = new LikeService();

        // make axios with credentials true
        this.likeService.http.defaults.withCredentials = true;

        if (this.initCheck) {
            this.check();
        }
    },

    methods: {
        toggle() {
            this.likeService
                .put(
                    this.likeApi,
                    {
                        favourite: this.liked
                            ? [this.model.data.id]
                            : this.model.data
                    },
                    {
                        params: {
                            type: this.model.type,
                            action: this.liked ? "del" : "add"
                        },
                        headers: {
                            'Authorization': 'SSO ' + this.token
                        }
                    }
                )
                .then(resp => (this.liked = !this.liked))
                .catch(err => {
                    if (err.status == 422) {
                        toast(err.detail, "snackbar-error");
                    }
                    console.log(err);
                });
        },

        check() {
            // $.ajax({
            //     url: this.checkFrom,
            //     type: "PUT",
            //     contentType: "application/json;charset=UTF-8",
            //     xhrFields: {
            //         withCredentials: true
            //     },
            //     dataType: "json",
            //     data: JSON.stringify({ "favourite": [this.model.data.id] }),
            //     crossDomain: true,
            //     success: (json, statusText, xhr) => {
            //         this.liked = true;
            //     },
            //     error: (err) => {}
            // });
            window.axios
                .put(this.checkFrom, { favourite: [this.model.data.id] },{headers: { 'Authorization': 'SSO ' + this.token}}, {withCredentials: true})
                .then(resp => (this.liked = true)).catch(err => {});
        }
    },

    computed: {
        text() {
            return this.liked ? this.fillable.liked : this.fillable.like;
        },

        fillable() {
            return {
                ...this.defaultText,
                ...this.fill
            };
        }
    }
};
</script>

<style></style>
