var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "media", class: { "mb-4": _vm.replied } },
    [
      _vm.comment.commenter
        ? [
            _c("div", { staticClass: "mr-3" }, [
              _c("div", { staticClass: "avatar" }, [
                _c("img", {
                  staticClass: "media-object",
                  attrs: {
                    src: _vm.comment.commenter.picture,
                    alt: _vm.comment.commenter.full_name + " avatar",
                  },
                }),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "media-body" },
              [
                _c("h6", { staticClass: "media-heading mt-0" }, [
                  _vm._v(
                    _vm._s(_vm.comment.commenter.room_name) +
                      "\n                "
                  ),
                  _c("span", { staticClass: "badge badge-success" }),
                  _vm._v(" "),
                  _c("small", { staticClass: "text-muted" }, [
                    _vm._v("· " + _vm._s(_vm.comment.created_at)),
                  ]),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "text-muted mb-0" }, [
                  _vm._v(_vm._s(_vm.comment.message)),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "media-footer text-right" }, [
                  !_vm.replied
                    ? _c(
                        "a",
                        {
                          staticClass: "btn btn-sm btn-primary btn-link",
                          attrs: {
                            href: "#",
                            rel: "tooltip",
                            title: "",
                            "data-original-title": "回复",
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.popReply(_vm.comment)
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("reply"),
                          ]),
                          _vm._v(" 回复"),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.comment.video
                    ? _c(
                        "a",
                        {
                          staticClass: "btn btn-sm btn-link",
                          attrs: {
                            href: _vm.comment.video.url,
                            target: "_blank",
                          },
                        },
                        [
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("link"),
                          ]),
                          _vm._v(" " + _vm._s(_vm.comment.video.name)),
                        ]
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _vm.hasReplies(_vm.comment)
                  ? _vm._l(_vm.comment.replies, function (reply) {
                      return _c("comment-item", {
                        key: reply.id,
                        attrs: { comment: reply, replied: true },
                      })
                    })
                  : _vm._e(),
              ],
              2
            ),
          ]
        : _c(
            "div",
            { staticClass: "alert alert-secondary", attrs: { role: "alert" } },
            [_vm._v("该留言已删除!")]
          ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }