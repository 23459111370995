var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "group-member-list-wrapper" }, [
    _c("div", { staticClass: "header" }, [
      _c(
        "span",
        { staticClass: "member-count text-ellipsis" },
        [
          _vm._v("\n      成员：\n      "),
          _vm.canViewOnlines ? _c("online-number") : _vm._e(),
          _vm._v(
            "\n      " +
              _vm._s(_vm.currentConversation.groupProfile.memberNum) +
              "\n    "
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "scroll-content" }, [
      _c("div", { staticClass: "group-member-list" }, [
        _c(
          "div",
          { staticClass: "list" },
          [
            _vm._l(_vm.members, function (member) {
              return [
                _c(
                  "popover",
                  { key: member.userID, attrs: { placement: "right" } },
                  [
                    _c("group-member-info", { attrs: { member: member } }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "group-member",
                        attrs: { slot: "reference" },
                        on: {
                          click: function ($event) {
                            _vm.currentMemberID = member.userID
                          },
                        },
                        slot: "reference",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "member-name text-ellipsis",
                            attrs: {
                              title: _vm.getGroupMemberAvatarText(member.role),
                            },
                          },
                          [
                            member.nick
                              ? _c("span", { attrs: { title: member.nick } }, [
                                  _vm._v(_vm._s(_vm.blk(member.nick))),
                                ])
                              : _c(
                                  "span",
                                  { attrs: { title: member.userID } },
                                  [_vm._v(_vm._s(member.userID))]
                                ),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            }),
          ],
          2
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "more" }, [
      _vm.showLoadMore
        ? _c(
            "a",
            {
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.loadMore.apply(null, arguments)
                },
              },
            },
            [_vm._v("查看更多")]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }