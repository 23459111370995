var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "card card-link-with-image",
      class: _vm.elClass,
      attrs: { href: _vm.lecture.url },
    },
    [
      _c(
        "div",
        {
          staticClass: "card-img-top position-relative overlay-hover-then-show",
        },
        [
          _c("img", {
            staticClass: "card-img-top",
            attrs: { src: _vm.lecture.thumb },
          }),
          _vm._v(" "),
          _vm._m(0),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [
        _c("p", { staticClass: "card-title text-truncate mb-1" }, [
          _vm._v(_vm._s(_vm.lecture.name)),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "d-flex justify-content-between small mb-1" },
          [
            _c(
              "div",
              { staticClass: "card-text text-truncate expert-name text-muted" },
              [_vm._v(_vm._s(_vm.lecture.experts.join(", ")))]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "views-number text-warning" }, [
              _c("i", { staticClass: "fas fa-users" }),
              _vm._v(" " + _vm._s(_vm.lecture.views ? _vm.lecture.views : 0)),
            ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _vm.needPurchased
        ? _c("div", { staticClass: "lecture-tag lecture-tag-pay" }, [
            _vm._v("付费"),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.remove.enable
        ? _c("div", { staticClass: "position-absolute pos-tr" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-sm btn-fab btn-warning remove-button",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.removeThisCard(_vm.lecture)
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-trash-alt" })]
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "card-img-overlay d-flex align-items-center justify-content-center",
      },
      [_c("i", { staticClass: "fas fa-play fa-3x" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }