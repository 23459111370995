var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      class: _vm.elClass,
      attrs: {
        href: "#like",
        title: _vm.liked ? _vm.defaultText.liked : _vm.defaultText.like,
      },
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.toggle.apply(null, arguments)
        },
      },
    },
    [
      _vm.fillable.icon && _vm.fillable.icon.length > 0
        ? _c("i", { class: [_vm.fillable.icon, { active: _vm.liked }] })
        : _vm._e(),
      _vm._v("\n    " + _vm._s(_vm.text) + "\n"),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }