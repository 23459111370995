<template>
  <div>
    <div class="pay-once" v-if="firstScreen">
      <div class="text-center v-title">{{ video.name }}</div>
      <div class="nav-breadcrumb card-breadcrumb v-breadcrumb">
        <ol class="breadcrumb justify-content-center">
          <li class="breadcrumb-item">观看人数：{{ video.views}}</li>
          <li class="breadcrumb-item">讲师：{{ experts }}</li>
          <li v-if="video.start_at" class="breadcrumb-item">开始时间：{{ video.start_at }}</li>
        </ol>
      </div>
      <p class="des">{{ video.description }}</p>
      <div class="text-center btns">
        <p class="money">￥{{ video.price }}</p>
        <span class="btn btn-success btn-lg btn-round" @click="switchScreen">立即购买</span>
        <br>
        <span class="btn btn-outline btn-lg btn-round btn-empty" @click="close">播放导学</span>
      </div>
    </div>
    <div v-else class="text-center pay-second">
      <div class="text-center pay-title">
        <p>扫一扫快捷支付</p>
        <p class="money">￥{{ video.price }}</p>
      </div>
      <ul :class="elClass" class="list-inline pay-list">
        <li
          v-for="(image, gateway) in qrcodes"
          :key="gateway"
          class="list-inline-item text-center px-2"
        >
          <div class="btn-title">
            <i class="fab" :class="faGateway(gateway)"></i>
            {{ payName(gateway) }}
          </div>
          <img :src="image" alt="qrcode">
          <div class="w-100 mt-3">手机{{ payName(gateway) }}扫一扫支付</div>
        </li>
      </ul>
      <span class="btn btn-outline btn-lg btn-round btn-empty" @click="switchScreen">返回上一步</span>
    </div>
  </div>
</template>

<script>
import { OrderService } from "../../services/OrderService";
import { toast } from "../../../helpers/utils";

export default {
  name: "PaymentCode",

  props: {
    elClass: {
      type: [Object, String]
    },

    callFrom: {
      type: String
    }
  },

  data() {
    return {
      api: this.callFrom,
      orderService: null,
      qrcodes: [],
      firstScreen: true, //支付第一步
      video: {
          name: '',
          views: '',
          start_at: null,
          description: '',
          price: ''
      }, //视频信息
      experts: null
    };
  },

  mounted() {
    this.orderService = new OrderService();

    // 监听用户登录事件
    this.orderService.hub().$on("user-logged-in", e => {
      this.getQrCodes();
    });

    this.getQrCodes();
  },

  methods: {
    getQrCodes() {
      this.orderService
        .qrcodes(this.api)
        .then(resp => {
          this.qrcodes = resp.qrcodes;
          this.video = resp.video;
          this.experts = resp.video.experts.join(",");

          // 加入订单频道
          this.orderService.joinOrderChannel(resp.order);
        })
        .catch(err => toast(err.data.message));
    },

    faGateway(gateway) {
      if (gateway == "wechat") {
        gateway = "weixin";
      }

      return "fa-" + gateway;
    },

    payName(gateway) {
        return gateway == "wechat" ? '微信' : '支付宝';
    },

    switchScreen() {
      this.firstScreen = !this.firstScreen;
    },

    close() {
      this.$emit('closed');
    }
  }
}
</script>

<style lang="scss">
.pay-once {
  color: #fff;
  font-size: 0.7rem;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 2rem 6rem;

  .v-title {
    font-size: 2rem;
    line-height: 1.2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .des {
    border-top: 1px solid #fff;
    padding-top: 0.5rem;
    font-size: 0.6rem;
    line-height: 1.6;
  }

  .btns span {
    line-height: 0.4;
  }
}

.pay-second {
  .pay-title {
    p {
      color: #fff;
      font-size: 1.2rem;
    }
  }

  .pay-list {
    color: #fff;

    li {
      .btn-title {
        color: #fff;
        font-size: 0.8rem;
        margin-bottom: 0.5rem;

        i {
          font-size: 1.4rem;
          margin-right: 0.3rem;
          vertical-align: top;
        }
      }

      img {
        width: 10rem;
        height: 10rem;
      }
    }
  }
}

.money {
  font-size: 1.2rem;
  font-weight: bold;
}

.btn.btn-empty {
  border: 1px solid #fff;
  color: #fff;
  line-height: 0.4;

  &:hover {
    background-color: #fff;
    color: #333;
    border-color: transparent;
  }
}
</style>
