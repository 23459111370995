import { BaseService } from "./BaseService";
import { ApplyApi } from "../helpers/config";

export class ApplyService extends BaseService
{
    ask(data) {
        return this.post(ApplyApi.ask, data);
    }

    check() {
        return this.get(ApplyApi.status);
    }
}
