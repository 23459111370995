import Echo from "laravel-echo";
import { connect } from "socket.io-client";

export class EchoService 
{
    static instance() {
        if (!EchoService.echo) {
            EchoService.echo = new Echo({
                broadcaster: "socket.io",
                host: window.location.host,
                client: connect
            });
        }

        return EchoService.echo;
    }
}
