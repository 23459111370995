import Axios from 'axios';

const site = {
	state: {
 		triedLogin:false,
	  	isPc:false,
	  	isIos:false,
	  	showLogin:false,//是否显示登录
		showRegister:false,//是否显示登记
		showPassword:false,//是否显示密码
		showGoLogin:false,//是否显示前往a站登录
	},
	getters: {
 	    site: state => state
	},
    mutations: {
	    // 更新state都用该方法
		  SetTried(state,sta){
		    state.triedLogin=sta
		  },
		  changeShowLogin(state,sta){
		    state.showLogin=sta
		  },
		  changeShowRegister(state,sta){
		    state.showRegister=sta
		  },
		  changeShowPassword(state,sta){
		    state.showPassword=sta
		  },
		  changeShowGoLogin(state,sta){
			state.showGoLogin=sta
		  }
	},
	actions: {
 	    SetTried({commit},sta){
		    commit('SetTried', sta)
	  	},
	  	// WeixinApi({},config){
		//     Axios.get("https://qp.91360.com/api/wechat/auth?callback=&link="+config.link).then((data)=>{
		//       wxapi.wxRegister(data.data,config);
		//     })
	  	// },
	  	changeShowLogin({commit},sta){
		    commit('changeShowLogin', sta)
	  	},
	  	changeShowRegister({commit},sta){
		    commit('changeShowRegister', sta)
		},
		changeShowPassword({commit},sta){
		    commit('changeShowPassword', sta)
		},
		changeShowGoLogin({commit},sta){
		    commit('changeShowGoLogin', sta)
	  	},
	}
}

export default site


