import { BaseService } from "./BaseService";
import { EchoService } from "./EchoService";

export class OrderService extends BaseService {
    qrcodes(api) {
        return this.post(api);
    }

    /**
     * 加入订单频道
     *
     * @param {String} order 订单号SN
     */
    joinOrderChannel(order) {
        EchoService.instance()
            .private("app.order." + order)
            .listen(".order.paid", e => {
                // 订单支付成功
                // 告知player 重新加载
                this.hub().$emit("switch-player", {
                    api: e.player,
                    isStream: false
                });

                // 告知重新加载订单
                this.hub().$emit("paied-successful", { order });
            })
            .listen(".order.other.events", e => {
                // 其他订单事件...
            });
    }
}
