var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("p", { class: _vm.elClass }, [
    _vm._v("\n\t" + _vm._s(_vm.formattedString) + "\n       \n\t"),
    _c(
      "span",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.text.length > _vm.maxChars,
            expression: "text.length > maxChars",
          },
        ],
      },
      [
        _c(
          "a",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isReadMore,
                expression: "!isReadMore",
              },
            ],
            attrs: { href: _vm.link, id: "readmore" },
            on: {
              click: function ($event) {
                return _vm.triggerReadMore($event, true)
              },
            },
          },
          [_vm._v(_vm._s(_vm.moreStr))]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isReadMore,
                expression: "isReadMore",
              },
            ],
            attrs: { href: _vm.link, id: "readmore" },
            on: {
              click: function ($event) {
                return _vm.triggerReadMore($event, false)
              },
            },
          },
          [_vm._v(_vm._s(_vm.lessStr))]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }