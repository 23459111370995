var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.elClass }, [
    _c(
      "button",
      {
        staticClass: "btn btn-info btn-sm",
        class: { disabled: !_vm.sendable },
        attrs: { type: "button", disabled: !_vm.sendable },
        on: { click: _vm.sendJxPin },
      },
      [_vm._v(_vm._s(_vm.buttonText))]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }