<template>
    <div class="row">
        <div :class="colClass" v-for="lecture in lectures" :key="lecture.id">
            <lecture-card elClass="card-less-padding" :lecture="lecture"></lecture-card>
        </div>
    </div>
</template>

<script>
import LectureCard from "../cards/LectureCard.vue";

export default {
    props: [
        "col",
        'loadFrom'
    ],

    data() {
        return {
            url: this.loadFrom,
            lectures: []
        }
    },

    mounted() {
        this.loadLectures();
    },

    methods: {
        loadLectures() {
            axios.get(this.url).then(resp => {
                this.lectures = resp.data.data;
            });
        }
    },

    computed: {
        colClass() {
            return 'col-md-' + (12 / this.col);
        }
    },

    components: {
        'lecture-card': LectureCard
    }
}
</script>
