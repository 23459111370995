var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { height: "91%" } },
    [
      _c("group-member-list", { attrs: { groupProfile: _vm.groupProfile } }),
      _vm._v(" "),
      _vm.editable
        ? _c(
            "div",
            { staticClass: "info-item" },
            [
              _c("div", { staticClass: "label" }, [
                _vm._v("\r\n            群公告\r\n        "),
              ]),
              _vm._v(" "),
              _c("el-input", {
                ref: "editNotification",
                attrs: { autofocus: "", size: "mini" },
                on: {
                  blur: function ($event) {
                    _vm.showEditNotification = false
                  },
                },
                nativeOn: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.editNotification.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.notification,
                  callback: function ($$v) {
                    _vm.notification = $$v
                  },
                  expression: "notification",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }