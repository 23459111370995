/**
 * 用户接口
 */
const UserApi = {
    // 检索用户是否登录
    check: '/user',

    // 检索用户sig
    sig: '/user/sig',

    // 获得可用的第三方登录 providers
    socialites: '/socialites',

    // 登录或者注册
    loginOrRegister: '/login-or-register',

    // 登录
    login: '/login',

    // 用户中心总览
    dashboard: '/dashboard/overview',

    // 我的收藏
    collections: '/account/collections',

    // 取消收藏:DELETE
    unStar: '/account/collections/{collection}',

    // 我的订单
    orders: '/account/orders',

    // 删除我的订单
    deleteOrder: '/account/orders/{order}',

    // 支付订单
    payOrder: '/account/orders/{order}/pay',

    // SSO
    sso: '/login/sso/{token}',

    // 注销
    signout: '/logout'
}

/** 申请接口 */
const ApplyApi = {
    ask: '/applies',

    status: '/applies/status'
}

const CommentApi = {
    /*
     * 发表评论
     */
    create: '/comments',

    // 回复评论
    reply: '/comments/{comment}'
}

const LectureApi = {
    /*
     * 播放地址
     */
    player: '/lectures/{lecture}/player',

    /*
     * 聊天
     */
    chat: '/rooms/{room}/send-chat',

    /**
     * brand new chat
     */
    chat2: '/rooms/{room}/send-chat-new',

    /**
    * 禁言和解禁
    */
    active: '/profile/active',
}

export {
    UserApi,
    ApplyApi,
    CommentApi,
    LectureApi
}
