import Vue from "vue";
import LectureCardList from "./LectureCardList.vue";
import UserMenu from './UserMenu.vue';
import AskFor from './AskFor.vue';
import Comment from '../comment/Comment.vue';
import LecutrePlayer from './LecturePlayer.vue';
import ReadMore from "../../../components/readmore/ReadMore.vue";
// import ExpertWithChat from './ExpertWithChat.vue';
import ExpertWithChatNew from './ExpertWithChatNew.vue';
import Like from "../general/Like.vue";
import LectureBreadcrumb from "./LectureBreadcrumb.vue";
// import Chat from 'vue-beautiful-chat';
import AppHeader from "./AppHeader.vue";
import TopBarPlugin from 'top-bar/dist/top-bar.ssr';
import store from './pcstore/index'

// Vue.use(VueChatScroll);
// Vue.use(Chat);
Vue.use(TopBarPlugin);
Vue.prototype.$store = store;
// disable tooltip
Vue.config.productionTip = false;
Vue.config.devtools = false;

Vue.component('app-header', AppHeader);
Vue.component("lecture-card-list", LectureCardList);
Vue.component("user-menu", UserMenu);
Vue.component('ask-for', AskFor);
Vue.component('comment', Comment);
Vue.component('lecture-player', LecutrePlayer);
Vue.component('read-more', ReadMore);
Vue.component('expert-with-chat-new', ExpertWithChatNew);
// Vue.component('expert-with-chat', ExpertWithChat);
Vue.component('like', Like);
Vue.component('lecture-breadcrumb', LectureBreadcrumb);

new Vue({
    el: "#app"
});
