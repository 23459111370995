import { BaseService } from "./BaseService";

export class LectureService extends BaseService {
    /**
     * 通过接口获得点播地址
     *
     * @param {String} api
     */
    player(api) {
        return this.get(api);
    }

    /**
     * 验证需要 密码访问得资源
     *
     * @param {String} url
     * @param {Object} data
     */
    auth(url, data) {
        return this.post(url, data);
    }

    /**
     * 登记
     *
     * @param {String} url
     * @param {Object} data
     */
    book(url, data) {
        return this.post(url, data);
    }

    /**
     * 增加访问记录
     *
     * @param {String} lectureId
     */
    lastVisit(lectureId) {
        return this.post(`/lectures/${lectureId}/last-visit`);
    }
}
