<template>
    <a :href="lecture.url" class="card card-link-with-image" :class="elClass">
        <div class="card-img-top position-relative overlay-hover-then-show">
            <img class="card-img-top" :src="lecture.thumb">
            <div class="card-img-overlay d-flex align-items-center justify-content-center">
                <i class="fas fa-play fa-3x"></i>
            </div>
        </div>
        <div class="card-body">
            <p class="card-title text-truncate mb-1">{{ lecture.name }}</p>
            <div class="d-flex justify-content-between small mb-1">
                <div class="card-text text-truncate expert-name text-muted">{{ lecture.experts.join(', ') }}</div>
                <div class="views-number text-warning"><i class="fas fa-users"></i> {{ lecture.views ? lecture.views : 0 }}</div>
            </div>
        </div>
        <div v-if="needPurchased" class="lecture-tag lecture-tag-pay">付费</div>
        <div v-if="remove.enable" class="position-absolute pos-tr">
            <button
                type="button"
                class="btn btn-sm btn-fab btn-warning remove-button"
                @click.stop.prevent="removeThisCard(lecture)"
            >
                <i class="fa fa-trash-alt"></i>
            </button>
        </div>
    </a>
</template>

<script>
import { swalWithBootstrapButtons } from "../../../helpers/utils";

export default {
    name: 'LectureCard',

    props: {
        elClass: {
            type: [String, Object]
        },

        lecture: {
            type: Object,
            required: true
        },

        remove: {
            type: Object,
            default() {
                return {}
            }
        }
    },

    data() {
        return {
            defaultRemoveOptions: {
                enable: false,
                title: '确定删除本条目吗?',
                desc: '你将无法恢复本记录!',
                type: 'warning'
            }
        }
    },

    methods: {
        removeThisCard(lecture) {
            if (!this.remove.enable) {
                return false;
            }

            swalWithBootstrapButtons({
                title: this.removeOptions.title,
                text: this.removeOptions.desc,
                type: this.removeOptions.type,
                confirmButtonText: '确定',
                cancelButtonText: '我再想想',
                showCancelButton: true
            }).then(result => {
                if (result.value) {
                    this.$emit('remove-lecture', { lecture });
                }
            });
        }
    },

    computed: {
        needPurchased() {
            return this.lecture.stage.price ? true : false;
        },

        priceText() {
            return this.lecture.stage.price ? ('￥' + this.lecture.stage.price) : '免费';
        },

        priceColor() {
            return this.lecture.stage.price ? 'lecture-pay' : 'lecture-free';
        },

        removeOptions() {
            return Object.assign({}, this.defaultRemoveOptions, this.remove);
        },

        views() {
            const views = this.lecture.views;

            if (!views) {
                return 0;
            } else if (views > 99999) {
                return '10w+';
            } else if (views > 9999) {
                return '1w+';
            } else if (views > 999) {
                return '1k+';
            } else if (views > 99) {
                return '100+';
            }

            return views;
        }
    }
}
</script>

<style lang="scss" scoped>
.remove-button {
    cursor: pointer;
    display: none;
}

.card {
    &:hover {
        .remove-button {
            display: block;
        }
    }
}

.lecture-tag {
    position: absolute;
    top: 0;
    right: 10px;
    color: #fff;
    padding: 0 5px;
    border-radius: 0 0 5px 5px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 75%;
}

.lecture-tag-pay {
    background-color: #e91e63;
}

.pos-tr {
    top: 5px;
    left: 5px;
}

.views-number {
    min-width: 4.5rem;
    text-align: right;
}
</style>
