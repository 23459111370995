<template>
  <div class="card" :class="elClass">
    <div class="card-header card-header-primary card-header-expert">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a id="expertTab" class="nav-link active" data-toggle="tab" href="#tab-expert">
            <i class="fas fa-user-md"></i> 专家
          </a>
        </li>
        <li class="nav-item" v-show="showChatTab">
        <a id="chatTab" class="nav-link" data-toggle="tab" href="#tab-interact">
            <i class="far fa-comments"></i> 互动
        </a>
        </li>
        <li class="nav-item" v-show="showChatTab">
        <a id="userTab" class="nav-link" data-toggle="tab" href="#tab-userlist">
            <i class="far fa-eye"></i> 在线用户
        </a>
        </li>
        <li class="nav-item" v-show="showChatTab">
        <a id="userTab" class="nav-link" data-toggle="tab" href="#tab-examine" :style="{'display':checkMessage == 1 ? 'table':'none'}">
            <!-- <input type="hidden" id="roomid" value='321' > -->
            <i class="far fa-eye"></i> 审核
        </a>
        </li>
      </ul>
    </div>
    <div class="card-body p-0">
      <div class="tab-content">
        <div id="tab-expert" class="tab-pane active">
          <slot></slot>
        </div>
        <template v-show="showChatTab">
            <div id="tab-interact" class="tab-pane">
                <chat-room @show-chat-tab="openChat" @close-chat-tab="closeChat"></chat-room>
            </div>
            <div id="tab-userlist" class="tab-pane">
                <group-profile
                    v-if="currentConversation.type === TIM.TYPES.CONV_GROUP"
                    :groupProfile="currentConversation.groupProfile"
                />
            </div>
             <div id="tab-examine" class="tab-pane">
                <chat-examine></chat-examine>
            </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import GroupProfile from '../../im/components/conversation/conversationProfile/group-profile.vue'

export default {
  name: "ExpertWithChatNew",

components: {
    GroupProfile
  },

  computed: {
    ...mapState({
      currentConversation: state => state.conversation.currentConversation
    })
  },

  props: {
    elClass: {
      type: [Object, String]
    }
  },

  data() {
    return {
      showChatTab: false,
      showUserTab: false,
      list: [],
      checkMessage: 1
    };
  },

  mounted() {
    this.calcChatWindowHeight();
    // this.$bus.$on("show-user-tab", e => {
    //   this.showUserTab = true;
    // });
    this.checkUser();
  },

  methods: {
    openChat() {
      this.showChatTab = true;
      $("#chatTab").tab("show");
    },

    closeChat() {
      $("#expertTab").tab("show");
      this.showChatTab = false;
    },

    calcChatWindowHeight() {
      $("#tab-interact").height($(window).height() - 185);
      $("#tab-examine").height($(window).height() - 185);
      $("#tab-userlist").height($(window).height() - 185);
      $("#tab-expert").css({
        height: $(window).height() - 185,
        "overflow-y": "auto"
      });
    },
    checkUser() {
        let checkmess = $("#checkmessage").val();
        console.log(checkmess);
        if(checkmess == 0){
            this.checkMessage = 0;
        }
    }
  }
};
</script>

<style>
</style>
