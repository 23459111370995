var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.elClass }, [
    _c("div", { staticClass: "comment-form-box" }, [
      _c("div", { staticClass: "comment-media clearfix" }, [
        _c("div", { staticClass: "comment-media-body" }, [
          _c(
            "form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.postComment.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "d-alert-box" }),
              _vm._v(" "),
              _c("div", { staticClass: "at-box" }),
              _vm._v(" "),
              _c("div", { staticClass: "form-group position-relative mb-0" }, [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.create.message,
                      expression: "create.message",
                    },
                  ],
                  staticClass: "form-control",
                  class: _vm.hasError("message"),
                  attrs: {
                    rows: "3",
                    placeholder: "输入内容，参与留言。",
                    required: "",
                  },
                  domProps: { value: _vm.create.message },
                  on: {
                    load: function ($event) {
                      return _vm.limitInput("create", $event)
                    },
                    keyup: function ($event) {
                      return _vm.limitInput("create", $event)
                    },
                    paste: function ($event) {
                      return _vm.limitInput("create", $event)
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.create, "message", $event.target.value)
                    },
                  },
                }),
                _vm._v(" "),
                _c("small", { staticClass: "invalid-feedback" }, [
                  _vm._v(_vm._s(_vm.errorFeedback("message"))),
                ]),
                _vm._v(" "),
                _c("small", {
                  staticClass: "form-text text-secondary d-count",
                  domProps: { innerHTML: _vm._s(_vm.limitaionText("create")) },
                }),
              ]),
              _vm._v(" "),
              _vm._m(0),
            ]
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _c("div", { staticClass: "d-comment-list" }, [
      _vm.hasComments
        ? _c(
            "div",
            { staticClass: "comment-lists list-unstyled" },
            _vm._l(_vm.comments.data, function (comment) {
              return _c("comment-item", {
                key: comment.id,
                attrs: { comment: comment },
                on: { "reply-button-clicked": _vm.popReplyDialog },
              })
            }),
            1
          )
        : _c("p", { staticClass: "lead" }, [_vm._v("目前尚无留言, 来个沙发?")]),
      _vm._v(" "),
      _vm.hasComments
        ? _c(
            "div",
            { staticClass: "d-flex mt-4 justify-content-end" },
            [
              _c("pagination", {
                attrs: { data: _vm.comments },
                on: { "pagination-change-page": _vm.loadComments },
              }),
            ],
            1
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal fade",
        attrs: { id: "replyComment", tabindex: "-1", role: "dialog" },
      },
      [
        _c(
          "div",
          { staticClass: "modal-dialog", attrs: { role: "document" } },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c(
                "form",
                {
                  staticClass: "comment-reply-form",
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.replyComment.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._m(1),
                  _vm._v(" "),
                  _c("div", { staticClass: "modal-body" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.reply.message,
                            expression: "reply.message",
                          },
                        ],
                        staticClass: "form-control",
                        class: _vm.hasError("message"),
                        attrs: {
                          required: "",
                          rows: "3",
                          placeholder: "您的回复",
                        },
                        domProps: { value: _vm.reply.message },
                        on: {
                          load: function ($event) {
                            return _vm.limitInput("reply", $event)
                          },
                          keyup: function ($event) {
                            return _vm.limitInput("reply", $event)
                          },
                          paste: function ($event) {
                            return _vm.limitInput("reply", $event)
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.reply, "message", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("small", { staticClass: "invalid-feedback" }, [
                        _vm._v(_vm._s(_vm.errorFeedback("message"))),
                      ]),
                      _vm._v(" "),
                      _c("small", {
                        staticClass: "form-text text-secondary d-count",
                        domProps: {
                          innerHTML: _vm._s(_vm.limitaionText("reply")),
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm._m(2),
                ]
              ),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-right" }, [
      _c(
        "button",
        { staticClass: "btn btn-warning btn-round", attrs: { type: "submit" } },
        [_vm._v("发表留言")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c("h5", { staticClass: "modal-title mt-0" }, [_vm._v("回复留言")]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: { type: "button", "data-dismiss": "modal" },
        },
        [_c("span", [_vm._v("×")])]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-footer" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-sm btn-rose btn-round",
          attrs: { type: "submit" },
        },
        [_vm._v("回复")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }