import { BaseService } from "./BaseService";
import { UserApi } from "../helpers/config";

export class UserService extends BaseService {
    /**
    * 检测用户是否以及登录
    */
    check() {
        return this.get(UserApi.check);
    }

    /**
    * 获得可用的第三方登录按钮
    */
    socialites() {
        return this.get(UserApi.socialites);
    }

    /**
    * 登录或者注册
    *
    * @param {Object} auth
    */
    loginOrRegister(auth, usingPassword) {
        if (usingPassword) {
            return this.post(UserApi.login, auth);
        }

        return this.post(UserApi.loginOrRegister, auth);
    }

    dashboard() {
        return this.get(UserApi.dashboard);
    }

    sso(user) {
        return this.get(UserApi.sso.replace("{token}", user.auth_token));
    }

    signout() {
        return this.get(UserApi.signout);
    }
}
