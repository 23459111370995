<template>
    <div :class="elClass">
        <button type="button" class="btn btn-info btn-sm" :class="{disabled: !sendable}" @click="sendJxPin" :disabled="!sendable">{{ buttonText }}</button>
    </div>
</template>

<script>
import bus from "../bus";
import { toast } from '../../../helpers/utils';

export default {
    name: 'JxPinButton',

    props: {
        elClass: {
            type: String
        },

        username: {
            type: String,
            default: 'mobile'
        },

        usage: {
            type: String,
            default: 'register'
        },

        cd: {
            type: Number,
            default: 60,
        },

        input: {
            type: String,
            required: true
        },

        api: {
            type: String,
            default: '/jxmdt/send'
        }
    },

    data() {
        return {
            sendable: true,
            text: '验证码',
            coolDown: this.cd,
            intv: null,
            mobile: null,
        }
    },

    mounted() {
        // listen event
        bus.$on('response-reached', this.disableAndCountDown);
    },

    methods: {
        sendJxPin() {
            if (!this.sendable) {
                return false;
            }

            if (!this.checkMobile()) {
                // toast('请输入手机号码', 'snackbar-error');
                $('#warn-text').show().text('请输入手机号码').delay(3000).hide(300);
                return false;
            }

            let data = {
                usage: this.usage
            };

            data[this.username] = this.mobile;

            // disable button immditely
            this.sendable = false;

            axios.post(this.api, data)
                .then(resp => {
                    // notify people
                    var warn_text =  resp.data.message;
                    if(resp.data.code == 1){
                        window.alert("您已完成答题！");
                    }else{
                     $('#warn-text').show().text(warn_text).css("background-color","green").delay(3000).hide(300);
                    }

                    // toast(resp.data.message);
                    // fire event
                    bus.$emit('response-reached');
                })
                .catch(err => {
                    const resp = err.response;
                    var warn_text =  resp.data.message;
                    $('#warn-text').show().text(warn_text).delay(3000).hide(300);
                    toast(resp.data.errors
                        ? _.partition(resp.data.errors, o => o[0])[0]
                        : resp.data.message, 'snackbar-error');
                    // fire event
                    bus.$emit('response-reached');
                });
        },

        countDown() {
            this.intv = setInterval(() => {
                if (this.coolDown == 0) {
                    return this.reset();
                }

                this.coolDown--;
            }, 1000)
        },

        reset() {
            clearInterval(this.intv);
            this.sendable = true;
            this.coolDown = this.cd;

            return true;
        },

        checkMobile() {
            this.mobile = $(this.input).val();
            return this.mobile && /^1\d{10}$/.test(this.mobile);
        },

        disableAndCountDown() {
            this.sendable = false;
            this.countDown();
        }
    },

    computed: {
        buttonText() {
            if (this.sendable) {
                return this.text;
            }

            return this.coolDown + '';
        }
    }
}
</script>
